export const fireworkConstants = {
    GET_ALL_FIREWORKS_REQUEST : 'GET_ALL_FIREWORKS_REQUEST',
    GET_ALL_FIREWORKS_SUCCESS : 'GET_ALL_FIREWORKS_SUCCESS',
    GET_ALL_FIREWORKS_FAILURE : 'GET_ALL_FIREWORKS_FAILURE',

    SAVE_FIREWORK_REQUEST : 'SAVE_FIREWORK_REQUEST',
    SAVE_FIREWORK_SUCCESS : 'SAVE_FIREWORK_SUCCESS',
    SAVE_FIREWORK_FAILURE : 'SAVE_FIREWORK_FAILURE',

    GET_VVK_REQUEST : 'GET_VVK_REQUEST',
    GET_VVK_SUCCESS : 'GET_VVK_SUCCESS',
    GET_VVK_FAILURE : 'GET_VVK_FAILURE',

    SAVE_VVK_REQUEST : 'SAVE_VVK_REQUEST',
    SAVE_VVK_SUCCESS : 'SAVE_VVK_SUCCESS',
    SAVE_VVK_FAILURE : 'SAVE_VVK_FAILURE',

    UPLOAD_FIREWORK_IMPORT_FILE_REQUEST : 'UPLOAD_FIREWORK_IMPORT_FILE_REQUEST',
    UPLOAD_FIREWORK_IMPORT_FILE_SUCCESS : 'UPLOAD_FIREWORK_IMPORT_FILE_SUCCESS',
    UPLOAD_FIREWORK_IMPORT_FILE_FAILURE : 'UPLOAD_FIREWORK_IMPORT_FILE_FAILURE',

    START_FIREWORK_EXCEL_EXPORT_REQUEST : 'START_FIREWORK_EXCEL_EXPORT_REQUEST',
    START_FIREWORK_EXCEL_EXPORT_SUCCESS : 'START_FIREWORK_EXCEL_EXPORT_SUCCESS',
    START_FIREWORK_EXCEL_EXPORT_FAILURE : 'START_FIREWORK_EXCEL_EXPORT_FAILURE',

    GET_ANNUAL_REPORTS_REQUEST : 'GET_ANNUAL_REPORTS_REQUEST',
    GET_ANNUAL_REPORTS_SUCCESS : 'GET_ANNUAL_REPORTS_SUCCESS',
    GET_ANNUAL_REPORTS_FAILURE : 'GET_ANNUAL_REPORTS_FAILURE',

    SAVE_ANNUAL_REPORTS_REQUEST : 'SAVE_ANNUAL_REPORTS_REQUEST',
    SAVE_ANNUAL_REPORTS_SUCCESS : 'SAVE_ANNUAL_REPORTS_SUCCESS',
    SAVE_ANNUAL_REPORTS_FAILURE : 'SAVE_ANNUAL_REPORTS_FAILURE',

    GET_MANUAL_TICKETS_REQUEST : 'GET_MANUAL_TICKETS_REQUEST',
    GET_MANUAL_TICKETS_SUCCESS : 'GET_MANUAL_TICKETS_SUCCESS',
    GET_MANUAL_TICKETS_FAILURE : 'GET_MANUAL_TICKETS_FAILURE',

    SAVE_MANUAL_TICKETS_REQUEST : 'SAVE_MANUAL_TICKETS_REQUEST',
    SAVE_MANUAL_TICKETS_SUCCESS : 'SAVE_MANUAL_TICKETS_SUCCESS',
    SAVE_MANUAL_TICKETS_FAILURE : 'SAVE_MANUAL_TICKETS_FAILURE',

    GET_STOCKTAKING_REQUEST : 'GET_STOCKTAKING_REQUEST',
    GET_STOCKTAKING_SUCCESS : 'GET_STOCKTAKING_SUCCESS',
    GET_STOCKTAKING_FAILURE : 'GET_STOCKTAKING_FAILURE',

    SAVE_STOCKTAKING_REQUEST : 'SAVE_STOCKTAKING_REQUEST',
    SAVE_STOCKTAKING_SUCCESS : 'SAVE_STOCKTAKING_SUCCESS',
    SAVE_STOCKTAKING_FAILURE : 'SAVE_STOCKTAKING_FAILURE',

    GET_SALES_STATISTICS_REQUEST : 'GET_SALES_STATISTICS_REQUEST',
    GET_SALES_STATISTICS_SUCCESS : 'GET_SALES_STATISTICS_SUCCESS',
    GET_SALES_STATISTICS_FAILURE : 'GET_SALES_STATISTICS_FAILURE',

    SAVE_SALES_STATISTICS_REQUEST : 'SAVE_SALES_STATISTICS_REQUEST',
    SAVE_SALES_STATISTICS_SUCCESS : 'SAVE_SALES_STATISTICS_SUCCESS',
    SAVE_SALES_STATISTICS_FAILURE : 'SAVE_SALES_STATISTICS_FAILURE',

    GET_DUTIES_REQUEST : 'GET_DUTIES_REQUEST',
    GET_DUTIES_SUCCESS : 'GET_DUTIES_SUCCESS',
    GET_DUTIES_FAILURE : 'GET_DUTIES_FAILURE',

    SAVE_DUTIES_REQUEST : 'SAVE_DUTIES_REQUEST',
    SAVE_DUTIES_SUCCESS : 'SAVE_DUTIES_SUCCESS',
    SAVE_DUTIES_FAILURE : 'SAVE_DUTIES_FAILURE',

    TOGGLE_EDIT_FIREWORK_MODEL : 'TOGGLE_EDIT_FIREWORK_MODEL',
    TOGGLE_VVK_MODAL : 'TOGGLE_VVK_MODAL',
    TOGGLE_IMPORT_MODAL : 'TOGGLE_IMPORT_MODAL',
    TOGGLE_ANNUAL_REPORTS_MODAL : 'TOGGLE_ANNUAL_REPORTS_MODAL',
    TOGGLE_MANUAL_TICKETS_MODAL : 'TOGGLE_MANUAL_TICKETS_MODAL',
    TOGGLE_STOCKTAKING_MODAL : 'TOGGLE_STOCKTAKING_MODAL',
    TOGGLE_SALES_STATISTICS_MODAL : 'TOGGLE_SALES_STATISTICS_MODAL',
    TOGGLE_DUTIES_MODAL : 'TOGGLE_DUTIES_MODAL',

    RESET_EXCEL_LINK : 'RESET_EXCEL_LINK',


    SET_DUMMY_PROPS : 'SET_DUMMY_PROPS',

};
