import { budgetConstants } from '../_constants';
import { budgetService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const budgetActions = {
    openModalBookInvoices,
    openModalAdjustForecast,
    openModalNewForecast,
    startAccountingConfirmation,
    toggleISTConfirmationModal,
    startISTConfirmation,
    getBudgetForYear,
    switchToForecast,
    loadForecastData,
    addForecast,
    saveAdjustedBudget,
    updateBudgetBWA,
    openForecastDeleteModal,
    deleteForecastItem,
    getProjectData,
    switchToProjectDetails,
    uploadInvoiceFile,
    updateForecastAmount,
    loadAllCreditors,
    openNewCreditorModal,
    saveNewCreditor,
    updateCreditor,
    deleteCreditor,
    toggleDeleteCreditorModal,
    toggleEditCreditorModal,
    openBookedInvoiceModal,
    openISTModal,
    createNextYearBudget,
    startBudgetOverviewExcelExport,
    startBudgetDetailsExcelExport,
    exportBudgetForecastToExcel,
    toggleEditInvoiceModal,
    updateInvoice,
    toggleDeletionModal,
    deleteInvoice,
    saveISTChange,
    saveTitleChange,
    saveLowBudgetChange,
    getAvailableBudgetYears
};

function getBudgetForYear(year) {
    return dispatch => {
        dispatch(request());

        return budgetService.getBudgetForYear(year)
            .then(
                budget => { 
                    dispatch(success(budget));
                    return budget;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return error;
                }
            );
    };

    function request() { return { type: budgetConstants.GET_BUDGET_REQUEST } }
    function success(budget) { return { type: budgetConstants.GET_BUDGET_SUCCESS, budget  } }
    function failure(error) { return { type: budgetConstants.GET_BUDGET_FAILURE, error } }
}

function openModalBookInvoices() {
    return dispatch => {
        dispatch({ type: budgetConstants.OPEN_MODAL_BOOK_INVOICES });
    };
}

function openModalAdjustForecast() {
    return dispatch => {
        dispatch({ type: budgetConstants.OPEN_MODAL_ADJUST_FORECAST });
    };
}

function openModalNewForecast() {
    return dispatch => {
        dispatch({ type: budgetConstants.OPEN_MODAL_NEW_FORECAST });
    };
}

function startAccountingConfirmation(forecastExternalUUID) {
    return dispatch => {
        dispatch({ type: budgetConstants.OPEN_MODAL_ACCOUNTING_CONFIRMATION, forecastExternalUUID });
    };
}

function toggleISTConfirmationModal(forecastExternalUUID) {
    return dispatch => {
        dispatch({ type: budgetConstants.TOGGLE_IST_CONFIRMATION_MODAL, forecastExternalUUID });
    };
}

function startISTConfirmation(externalUUID) {
    return dispatch => {
        dispatch(request());

        budgetService.startISTConfirmation(externalUUID)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.START_IST_CONFIRMATION_REQUEST } }
    function success(forecast) { return { type: budgetConstants.START_IST_CONFIRMATION_SUCCESS, forecast } }
    function failure(error) { return { type: budgetConstants.START_IST_CONFIRMATION_FAILURE, error } }
}

function switchToForecast(forecastExternalUUID,currentProjectTitle,currentProjectNumber) {
    return dispatch => {
        dispatch({ type: budgetConstants.SWITCH_TO_FORECAST, forecastExternalUUID, currentProjectTitle, currentProjectNumber });
        history.push('/budget/forecast')
    };
}

function loadForecastData(externalUUID) {
    return dispatch => {
        dispatch(request());

        return budgetService.getForecastData(externalUUID)
            .then(
                forecast => { 
                    dispatch(success(forecast));
                    return forecast;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return error;
                }
            );
    };

    function request() { return { type: budgetConstants.GET_FORECAST_REQUEST } }
    function success(forecast) { return { type: budgetConstants.GET_FORECAST_SUCCESS, forecast  } }
    function failure(error) { return { type: budgetConstants.GET_FORECAST_FAILURE, error } }
}

function addForecast(newForecast) {
    return dispatch => {
        dispatch(request());

        return budgetService.addForecast(newForecast)
            .then(
                newForecast => { 
                    if (newForecast.errorMessages && newForecast.errorMessages.length > 0) {
                        dispatch(failure(newForecast.errorMessages));
                    } else {
                        dispatch(success(newForecast))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.ADD_FORECAST_REQUEST } }
    function success(newForecast) { return { type: budgetConstants.ADD_FORECAST_SUCCESS, newForecast  } }
    function failure(error) { return { type: budgetConstants.ADD_FORECAST_FAILURE, error } }
}

function saveAdjustedBudget(adjustedBudget) {
    return dispatch => {
        dispatch(request());

        return budgetService.saveAdjustedBudget(adjustedBudget)
            .then(
                adjustedBudget => { 
                    if (adjustedBudget.errorMessages && adjustedBudget.errorMessages.length > 0) {
                        dispatch(failure(adjustedBudget.errorMessages));
                    } else {
                        dispatch(success(adjustedBudget.result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.ADJUST_BUDGET_REQUEST } }
    function success(result) { return { type: budgetConstants.ADJUST_BUDGET_SUCCESS, result  } }
    function failure(error) { return { type: budgetConstants.ADJUST_BUDGET_FAILURE, error } }
}

function updateBudgetBWA(budgetUUID, bwaHMGField, bwaHTGField, bwaHVGField) {
    return dispatch => {
        dispatch(request());

        return budgetService.updateBudgetBWA(budgetUUID, bwaHMGField, bwaHTGField, bwaHVGField)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages));
                    } else {
                        console.log("Successes:")
                        console.log(result);
                        dispatch(success(result.result))
                    }
                    return result;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return error;
                }
            );
    };

    function request() { return { type: budgetConstants.SAVE_BUDGET_BWA_REQUEST } }
    function success(result) { return { type: budgetConstants.SAVE_BUDGET_BWA_SUCCESS, result  } }
    function failure(error) { return { type: budgetConstants.SAVE_BUDGET_BWA_FAILURE, error } }
}


function openForecastDeleteModal(forecastExternalUUID) {
    return dispatch => {
        dispatch({ type: budgetConstants.OPEN_DELETE_FORECAST_MODAL, forecastExternalUUID });
    };
}

function deleteForecastItem(forecastExternalUUID) {
    return dispatch => {
        dispatch(request());

       return budgetService.deleteForecastData(forecastExternalUUID)
            .then(
                forecast => { 
                    dispatch(success(forecast));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.DELETE_FORECAST_REQUEST } }
    function success(forecast) { return { type: budgetConstants.DELETE_FORECAST_SUCCESS, forecast  } }
    function failure(error) { return { type: budgetConstants.DELETE_FORECAST_FAILURE, error } }
}

function switchToProjectDetails(projectItemExternalUUID) {
    return dispatch => {
        dispatch({ type: budgetConstants.SWITCH_TO_PROJECT_DETAILS, projectItemExternalUUID });
        history.push('/budget/details')
    };
}

function getProjectData(projectItemExternalUUID) {
    return dispatch => {
        dispatch(request());

        budgetService.getProjectData(projectItemExternalUUID)
            .then(
                project => { 
                    dispatch(success(project));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.GET_PROJECT_DETAILS_REQUEST } }
    function success(project) { return { type: budgetConstants.GET_PROJECT_DETAILS_SUCCESS, project  } }
    function failure(error) { return { type: budgetConstants.GET_PROJECT_DETAILS_FAILURE, error } }
}

function uploadInvoiceFile(data, selectedYear) {
    return dispatch => {
        dispatch(request());

        return budgetService.uploadInvoiceFile(data)
            .then(
                result => { 
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                    } else {
                        dispatch(success(result))
                        dispatch(getBudgetForYear(selectedYear))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.UPLOAD_INVOICES_REQUEST } }
    function success(result) { return { type: budgetConstants.UPLOAD_INVOICES_SUCCESS, result  } }
    function failure(errorMessages) { return { type: budgetConstants.UPLOAD_INVOICES_FAILURE, errorMessages } }
}

function updateForecastAmount(currentForecastUUID, forecastExternalUUID, amountToReduce, reduceToZero, updateAmount, amount) {
    return dispatch => {
        dispatch(request());

        return budgetService.updateForecastAmount(forecastExternalUUID, amountToReduce, reduceToZero, updateAmount, amount)
            .then(
                result => {
                    dispatch(budgetActions.loadForecastData(currentForecastUUID)).then(() => {
                        if (result.errorMessages && result.errorMessages.length > 0) {
                            dispatch(failure(result.errorMessages))
                        } else {
                            dispatch(success(result))
                        }
                    });
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.REDUCE_FORECAST_REQUEST } }
    function success(result) { return { type: budgetConstants.REDUCE_FORECAST_SUCCESS, result  } }
    function failure(errorMessages) { return { type: budgetConstants.REDUCE_FORECAST_FAILURE, errorMessages } }
}

function loadAllCreditors() {
    return dispatch => {
        dispatch(request());

        return budgetService.loadAllCreditors()
            .then(
                result => { 
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                    } else {
                        dispatch(success(result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.LOAD_ALL_CREDITORS_REQUEST } }
    function success(result) { return { type: budgetConstants.LOAD_ALL_CREDITORS_SUCCESS, result  } }
    function failure(errorMessages) { return { type: budgetConstants.LOAD_ALL_CREDITORS_FAILURE, errorMessages } }
}

function openNewCreditorModal() {
    return dispatch => {
        dispatch({ type: budgetConstants.OPEN_MODAL_NEW_CREDITOR });
    };
}

function saveNewCreditor(newCreditor) {
    return dispatch => {
        dispatch(request());

        return budgetService.saveNewCreditor(newCreditor)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                    } else {
                        dispatch(success(result));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.SAVE_NEW_CREDITOR_REQUEST } }
    function success(result) { return { type: budgetConstants.SAVE_NEW_CREDITOR_SUCCESS, result } }
    function failure(errorMessages) { return { type: budgetConstants.SAVE_NEW_CREDITOR_FAILURE, errorMessages } }
}

function updateCreditor(newCreditor) {
    return dispatch => {
        dispatch(request());

        return budgetService.updateCreditor(newCreditor)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                    } else {
                        dispatch(success(result));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.UPDATE_CREDITOR_REQUEST } }
    function success(result) { return { type: budgetConstants.UPDATE_CREDITOR_SUCCESS, result  } }
    function failure(errorMessages) { return { type: budgetConstants.UPDATE_CREDITOR_FAILURE, errorMessages } }
}

function deleteCreditor(id) {
    return dispatch => {
        dispatch(request());

        return budgetService.deleteCreditor(id)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                    } else {
                        dispatch(success(result));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.DELETE_CREDITOR_REQUEST } }
    function success(result) { return { type: budgetConstants.DELETE_CREDITOR_SUCCESS, result  } }
    function failure(errorMessages) { return { type: budgetConstants.DELETE_CREDITOR_FAILURE, errorMessages } }
}

function toggleDeleteCreditorModal(creditor) {
    return dispatch => {
        dispatch(request(creditor));
    };
    function request(creditor) { return { type: budgetConstants.TOGGLE_DELETE_CREDITOR_MODAL, creditor: creditor } }
}

function toggleEditCreditorModal() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: budgetConstants.TOGGLE_EDIT_CREDITOR_MODAL } }
}

function openISTModal() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: budgetConstants.TOGGLE_IST_MODAL } }
}

function openBookedInvoiceModal(isModalOpen, year) {
    return dispatch => {
       
        if(!isModalOpen){
            dispatch(request());
            dispatch({ type: budgetConstants.TOGGLE_BOOKED_INVOICE_MODAL});
            budgetService.getBookedInvoices(year)
            .then(
                invoices => { 
                    dispatch(success(invoices));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
        }else{
            dispatch({ type: budgetConstants.TOGGLE_BOOKED_INVOICE_MODAL});
        }
        
    };

    function request() { return { type: budgetConstants.GET_BOOKED_INVOICES_REQUEST } }
    function success(invoices) { return { type: budgetConstants.GET_BOOKED_INVOICES_SUCCESS, invoices  } }
    function failure(error) { return { type: budgetConstants.GET_BOOKED_INVOICES_FAILURE, error } }
}

function createNextYearBudget() {
    return dispatch => {
        dispatch(request());

        return budgetService.createNextYearBudget()
            .then(
                result => { 
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                    } else {
                        dispatch(success(result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.CREATE_NEXT_YEAR_REQUEST } }
    function success(result) { return { type: budgetConstants.CREATE_NEXT_YEAR_SUCCESS, result  } }
    function failure(errorMessages) { return { type: budgetConstants.CREATE_NEXT_YEAR_FAILURE, errorMessages } }
}

function startBudgetOverviewExcelExport(selectedYear) {
    return dispatch => {
        dispatch(request());

        return budgetService.startBudgetOverviewExcelExport(selectedYear)
            .then(
                result => { 
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                    } else {
                        dispatch(success(result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.START_BUDGET_EXCEL_EXPORT_REQUEST } }
    function success(result) { return { type: budgetConstants.START_BUDGET_EXCEL_EXPORT_SUCCESS, result  } }
    function failure(errorMessages) { return { type: budgetConstants.START_BUDGET_EXCEL_EXPORT_FAILURE, errorMessages } }
}

function startBudgetDetailsExcelExport(projectExternalUUID) {
    return dispatch => {
        dispatch(request());

        return budgetService.startBudgetDetailsExcelExport(projectExternalUUID)
            .then(
                result => { 
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                    } else {
                        dispatch(success(result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.START_BUDGET_EXCEL_EXPORT_REQUEST } }
    function success(result) { return { type: budgetConstants.START_BUDGET_EXCEL_EXPORT_SUCCESS, result  } }
    function failure(errorMessages) { return { type: budgetConstants.START_BUDGET_EXCEL_EXPORT_FAILURE, errorMessages } }
}

function exportBudgetForecastToExcel(forecastUUID) {
    return dispatch => {
        dispatch(request());

        return budgetService.exportBudgetForecastToExcel(forecastUUID)
            .then(
                result => { 
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                    } else {
                        dispatch(success(result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.START_BUDGET_EXCEL_EXPORT_REQUEST } }
    function success(result) { return { type: budgetConstants.START_BUDGET_EXCEL_EXPORT_SUCCESS, result  } }
    function failure(errorMessages) { return { type: budgetConstants.START_BUDGET_EXCEL_EXPORT_FAILURE, errorMessages } }
}

function toggleEditInvoiceModal() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: budgetConstants.TOGGLE_EDIT_INVOICE_MODAL } }
}

function toggleDeletionModal(invoiceId) {
    return dispatch => {
        dispatch(request(invoiceId));
    };
    function request(invoiceId) { return { type: budgetConstants.TOGGLE_DELETION_MODAL, invoiceId: invoiceId } }
}

function updateInvoice(projectUUID, invoice) {
    return dispatch => {
        dispatch(request());

        return budgetService.updateInvoice(projectUUID, invoice)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                    } else {
                        dispatch(success(result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.UPDATE_INVOICE_REQUEST } }
    function success(result) { return { type: budgetConstants.UPDATE_INVOICE_SUCCESS, result  } }
    function failure(errorMessages) { return { type: budgetConstants.UPDATE_INVOICE_FAILURE, errorMessages } }
}

function deleteInvoice(externalUUID) {
    return dispatch => {
        dispatch(request());

        return budgetService.deleteInvoice(externalUUID)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                    } else {
                        dispatch(success(result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.DELETE_INVOICE_REQUEST } }
    function success(result) { return { type: budgetConstants.DELETE_INVOICE_SUCCESS, result } }
    function failure(errorMessages) { return { type: budgetConstants.DELETE_INVOICE_FAILURE, errorMessages } }
}

function saveISTChange(ISTValue, selectedYear) {
    return dispatch => {
        dispatch(request());

        return budgetService.saveISTChange(ISTValue,selectedYear)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                    } else {
                        dispatch(success(result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.SAVE_IST_CHANGE_REQUEST } }
    function success(result) { return { type: budgetConstants.SAVE_IST_CHANGE_SUCCESS, result } }
    function failure(errorMessages) { return { type: budgetConstants.SAVE_IST_CHANGE_FAILURE, errorMessages } }
}


function saveTitleChange(projectItemUUID, newTitle) {
    return dispatch => {
        dispatch(request());

        return budgetService.saveTitleChange(projectItemUUID,newTitle)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                    } else {
                        dispatch(success(result))
                    }
                    return result;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return error;
                }
            );
    };

    function request() { return { type: budgetConstants.UPDATE_TITLE_REQUEST } }
    function success(result) { return { type: budgetConstants.UPDATE_TITLE_SUCCESS, result } }
    function failure(errorMessages) { return { type: budgetConstants.UPDATE_TITLE_FAILURE, errorMessages } }
}

function saveLowBudgetChange(projectItemUUID, newPercentage) {
    return dispatch => {
        dispatch(request());

        return budgetService.saveLowBudgetChange(projectItemUUID,newPercentage)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                    } else {
                        dispatch(success(result))
                    }
                    return result;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return error;
                }
            );
    };

    function request() { return { type: budgetConstants.UPDATE_LOWBUDGET_REQUEST } }
    function success(result) { return { type: budgetConstants.UPDATE_LOWBUDGET_SUCCESS, result } }
    function failure(errorMessages) { return { type: budgetConstants.UPDATE_LOWBUDGET_FAILURE, errorMessages } }
}

function getAvailableBudgetYears() {
    return dispatch => {
        dispatch(request());

       return budgetService.getAvailableBudgetYears()
            .then(
                years => { 
                    dispatch(success(years));
                    return years;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: budgetConstants.GET_AVAILABLE_BUDGET_YEARS_REQUEST } }
    function success(years) { return { type: budgetConstants.GET_AVAILABLE_BUDGET_YEARS_SUCCESS, years  } }
    function failure(error) { return { type: budgetConstants.GET_AVAILABLE_BUDGET_YEARS_FAILURE, error } }
}