import { addressConstants } from "../_constants";

export function addressdata(state={items:[]}, action) {
    switch (action.type) {
        case addressConstants.TOGGLE_MODAL_IMPORT:
            return {
                ...state,
                uploadedAddresses: [],
                errorMessages: null,
                warningMessages: null,
                imported: false,
                importModal: !state.importModal,
            };

        case addressConstants.HANDLE_EDIT:
            return {
                ...state,
                editIndex: action.index
            };

        case addressConstants.ADD_ADDRESS_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                imported: false,
                warningMessages: ""
            };

        case addressConstants.ADD_ADDRESS_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                uploadedAddresses: []
            };

        case addressConstants.ADD_ADDRESS_DATA_FAILURE:
            return {
                ...state,
                loading: true,
                uploadedAddresses: [],
                errorMessages: action.errorMessages
            };

        case addressConstants.GET_ADDRESSES_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case addressConstants.GET_ADDRESSES_SUCCESS:

            return {
                ...state,
                loading: false,
                addresses: action.result
            };

        case addressConstants.GET_ADDRESSES_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessages: action.errorMessages
            };

        case addressConstants.UPLOAD_ADDRESS_FILE_REQUEST:
            return {
                ...state,
                submitted: true,
                loading: true
            };

        case addressConstants.UPLOAD_ADDRESS_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                imported: true,
                uploadedAddresses: action.result.result,
                warningMessages: action.result.errorMessages
            };

        case addressConstants.UPLOAD_ADDRESS_FILE_FAILURE:
            return {
                ...state,
                loading: false,
                imported: false,
                errorMessages: action.result.errorMessages
            };

        case addressConstants.UPDATE_ADDRESS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case addressConstants.UPDATE_ADDRESS_SUCCESS:
            let oldList = state.addresses;
            oldList[state.editIndex] = action.result;
            return {
                ...state,
                editIndex: -1,
                loading: false,
                addresses: oldList
            };

        case addressConstants.UPDATE_ADDRESS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessages: action.errorMessages
            };

        case addressConstants.GET_SEARCH_INFO_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case addressConstants.GET_SEARCH_INFO_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                searchData: action.result
            };

        case addressConstants.GET_SEARCH_INFO_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessages: action.errorMessages
            };

        case addressConstants.SEARCH_REQUEST:
            return {
                ...state,
                loading: true
            };

        case addressConstants.SEARCH_REQUEST_SUCCESS:
            return {
                ...state,
                addresses: action.result,
                loading: false
            };

        case addressConstants.SEARCH_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessages: action.errorMessages
            };

        default: // need this for default case
            return state
    }

}