import {eventConstants} from "../_constants/event.constants";

export function event(state={}, action) {

  switch (action.type) {

    case eventConstants.GET_EVENTS_OVERVIEW_REQUEST:
      return {
        ...state,
        loading: true,
        eventOverview: undefined,
        errorMessage: undefined,
      }

    case eventConstants.GET_EVENTS_OVERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        eventOverview: action.result
      }

    case eventConstants.GET_EVENTS_OVERVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage
      }


    case eventConstants.GET_AVAILABLE_YEARS_REQUEST:
      return {
        ...state,
        availableEventYearsLoading: true,
        availableYears: undefined,
        errorMessage: undefined,
      }

    case eventConstants.GET_AVAILABLE_YEARS_SUCCESS:
      return {
        ...state,
        availableEventYearsLoading: false,
        availableYears: action.result
      }

    case eventConstants.GET_AVAILABLE_YEARS_FAILURE:
      return {
        ...state,
        availableEventYearsLoading: false,
        errorMessage: action.errorMessage
      }


    case eventConstants.CREATE_NEW_EVENT_REQUEST:
      return {
        ...state,
        createEventLoading: true,
        createEventErrorMessage: undefined
      }

    case eventConstants.CREATE_NEW_EVENT_SUCCESS:
      return {
        ...state,
        createEventLoading: false,
      }

    case eventConstants.CREATE_NEW_EVENT_FAILURE:
      return {
        ...state,
        createEventLoading: false,
        createEventErrorMessage: action.errorMessage
      }

    case eventConstants.GET_EVENT_CATEGORIES_REQUEST:
      return {
        ...state,
        eventCategoriesLoading: true,
        eventCategoriesErrorMessage: undefined
      }

    case eventConstants.GET_EVENT_CATEGORIES_SUCCESS:
      return {
        ...state,
        eventCategoriesLoading: false,
        eventCategories: action.result
      }

    case eventConstants.GET_EVENT_CATEGORIES_FAILURE:
      return {
        ...state,
        eventCategoriesLoading: false,
        eventCategoriesErrorMessage: action.errorMessage
      }

    case eventConstants.UPDATE_EVENT_CATEGORIES_REQUEST:
      return {
        ...state,
        updateCategoriesLoading: true,
        eventCategoriesErrorMessage: undefined
      }

    case eventConstants.UPDATE_EVENT_CATEGORIES_SUCCESS:
      return {
        ...state,
        updateCategoriesLoading: false,
        eventCategories: undefined
      }

    case eventConstants.UPDATE_EVENT_CATEGORIES_FAILURE:
      return {
        ...state,
        updateCategoriesLoading: false,
        eventCategoriesErrorMessage: action.errorMessage
      }

    case eventConstants.SAVE_EARNING_OR_EXPENSE_REQUEST:
      return {
        ...state,
        saveEarningOrExpenseLoading: true,
        saveEarningOrExpenseErrorMessage: undefined
      };

    case eventConstants.SAVE_EARNING_OR_EXPENSE_SUCCESS:
      return {
        ...state,
        saveEarningOrExpenseLoading: false,

      };

    case eventConstants.SAVE_EARNING_OR_EXPENSE_FAILURE:
      return {
        ...state,
        saveEarningOrExpenseLoading: false,
        saveEarningOrExpenseErrorMessage: action.errorMessage
      };

    case eventConstants.DELETE_EARNING_OR_EXPENSE_REQUEST:
      return {
        ...state,
        deletionLoading: true,
        deletionErrorMessage: undefined
      }

    case eventConstants.DELETE_EARNING_OR_EXPENSE_SUCCESS:
      return {
        ...state,
        deletionLoading: false,
      }

    case eventConstants.DELETE_EARNING_OR_EXPENSE_FAILURE:
      return {
        ...state,
        deletionLoading: false,
        deletionErrorMessage: action.errorMessage
      }

    case eventConstants.SET_EVENT_PLAN_VALUE_REQUEST:
      return {
        ...state,
        setPlanValueLoading: true,
        setPlanValueErrorMessage: undefined,
      }

    case eventConstants.SET_EVENT_PLAN_VALUE_SUCCESS:
      return {
        ...state,
        setPlanValueLoading: false,
      }

    case eventConstants.SET_EVENT_PLAN_VALUE_FAILURE:
      return {
        ...state,
        setPlanValueLoading: false,
        setPlanValueErrorMessage: action.errorMessage,
      }




    default:
      return state;
  }
}