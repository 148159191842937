export function authHeader() {
    // return authorization header with jwt token
    let storedUser = localStorage.getItem('hmtguser');
    if(!storedUser){
        return {
            'Content-Type': 'application/json'
        };
    }
    
    let user = JSON.parse(storedUser);

    if (user && user.access_token) {
        return {
            'Authorization': 'Bearer ' + user.access_token,
            'Content-Type': 'application/json'
        };
    } else {
        return {
            'Content-Type': 'application/json'
        };
    }
}