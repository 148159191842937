import { authHeader } from '../_helpers';
import "isomorphic-fetch";
import { history } from '../_helpers';
import { handleResponse } from './responseHandler';

export const budgetService = {
    getBudgetForYear,
    getForecastData,
    addForecast,
    saveAdjustedBudget,
    updateBudgetBWA,
    deleteForecastData,
    getProjectData,
    uploadInvoiceFile,
    updateForecastAmount,
    loadAllCreditors,
    saveNewCreditor,
    updateCreditor,
    deleteCreditor,
    getBookedInvoices,
    createNextYearBudget,
    startBudgetOverviewExcelExport,
    startBudgetDetailsExcelExport,
    exportBudgetForecastToExcel,
    updateInvoice,
    deleteInvoice,
    saveISTChange,
    saveTitleChange,
    saveLowBudgetChange,
    getAvailableBudgetYears,
    startISTConfirmation
};

function getBudgetForYear(year) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/'+year, requestOptions).then(handleResponse);
}

function getForecastData(externalUUID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/forecast/'+externalUUID, requestOptions).then(handleResponse);
}

function addForecast(forecast) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(forecast)
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/forecast/', requestOptions).then(handleResponse);
}

function saveAdjustedBudget(adjustedBudget) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(adjustedBudget)
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/', requestOptions).then(handleResponse);
}

function updateBudgetBWA(budgetUUID, bwaHMGField, bwaHTGField, bwaHVGField) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({budgetExternalUUID: budgetUUID, bwaHMGField: bwaHMGField, bwaHTGField: bwaHTGField, bwaHVGField: bwaHVGField})
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/bwa', requestOptions).then(handleResponse);
}

function deleteForecastData(forecastExternalUUID) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/forecast/'+forecastExternalUUID, requestOptions).then(handleResponse);
}

function getProjectData(externalUUID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/project/'+externalUUID, requestOptions).then(handleResponse);
}

function uploadInvoiceFile(data) {
    let user = JSON.parse(localStorage.getItem('hmtguser'));

    let header = {
      'Authorization': 'Bearer ' + user.access_token,
    };

    const requestOptions = {
      method: 'POST',
      headers: header,
      body: data
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/invoices', requestOptions).then(handleResponse);
}

function updateForecastAmount(forecastExternalUUID, amountToReduce, reduceToZero, updateAmount, amount) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({
            reduceToZero: reduceToZero,
            reduceAmount:amountToReduce,
            updateAmount: updateAmount,
            amount: amount,
            forecastItemExternalUUID : forecastExternalUUID
        })
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/forecast', requestOptions).then(handleResponse);
}

function loadAllCreditors() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/creditors', requestOptions).then(handleResponse);
}

function saveNewCreditor(newCreditor) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(newCreditor)
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/creditors/add', requestOptions).then(handleResponse);
}

function updateCreditor(creditor) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(creditor)
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/creditors/update', requestOptions).then(handleResponse);
}

function deleteCreditor(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(id)
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/creditors/delete/' + id, requestOptions).then(handleResponse);
}

function getBookedInvoices(year) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/bookedinvoices/' + year, requestOptions).then(handleResponse);
}

function createNextYearBudget() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/createnextyear', requestOptions).then(handleResponse);
}

function startBudgetOverviewExcelExport(selectedYear) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/excelexport/'+selectedYear, requestOptions).then(handleResponse);
}

function startBudgetDetailsExcelExport(projectExternalUUID) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/details/excelexport/'+projectExternalUUID, requestOptions).then(handleResponse);
}

function exportBudgetForecastToExcel(forecastUUID) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/forecast/excelexport/'+forecastUUID, requestOptions).then(handleResponse);
}

function updateInvoice(externalUUID, invoice) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(invoice)
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/invoice/update/' + externalUUID, requestOptions).then(handleResponse);
}

function deleteInvoice(invoiceId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/invoice/delete/' + invoiceId, requestOptions).then(handleResponse);
}

function saveISTChange(ISTValue,selectedYear) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'ISTValue':ISTValue, 'year':selectedYear})
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/ISTChange', requestOptions).then(handleResponse);
}

function saveTitleChange(projectItemUUID,newTitle) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'projectItemUUID':projectItemUUID, 'newTitle':newTitle})
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/saveTitle', requestOptions).then(handleResponse);
}

function saveLowBudgetChange(projectItemUUID,newPercentage) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({'projectItemUUID':projectItemUUID, 'newPercentage':newPercentage})
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/saveLowBudget', requestOptions).then(handleResponse);
}

function getAvailableBudgetYears() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/availableYears', requestOptions).then(handleResponse);
}

function startISTConfirmation(externalUUID) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/budget/istconfirmation/' + externalUUID, requestOptions).then(handleResponse);
}