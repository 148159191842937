import {giftCardConstants} from '../_constants';
import {giftcardService} from "../_services";

export const giftcardActions = {
    getGiftCards,
    getGiftCardOverview,
    addGiftCard,
    toggleAddModal,
    toggleImportModal,
    startExcelExport,
    uploadImportFile,
    toggleRedeemModal,
    toggleRedemptionModal,
    redeemGiftcard
};

function getGiftCards(name, year, month, type) {
    return dispatch => {
        dispatch(request());

        return giftcardService.getGiftCards(name, year, month, type)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: giftCardConstants.GET_GIFT_CARDS_REQUEST } }
    function success(result) { return { type: giftCardConstants.GET_GIFT_CARDS_SUCCESS, result } }
    function failure(errorMessages) { return { type: giftCardConstants.GET_GIFT_CARDS_FAILURE, errorMessages } }
}

function getGiftCardOverview() {
    return dispatch => {
        dispatch(request());

        return giftcardService.getGiftCardOverview()
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                    } else {
                        dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: giftCardConstants.GET_GIFT_CARD_OVERVIEW_REQUEST } }
    function success(result) { return { type: giftCardConstants.GET_GIFT_CARD_OVERVIEW_SUCCESS, result } }
    function failure(errorMessages) { return { type: giftCardConstants.GET_GIFT_CARD_OVERVIEW_FAILURE, errorMessages } }
}

function addGiftCard(giftCard) {
    return dispatch => {
        dispatch(request());

        giftcardService.addGiftCard(giftCard)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: giftCardConstants.ADD_GIFT_CARD_REQUEST } }
    function success(result) { return { type: giftCardConstants.ADD_GIFT_CARD_SUCCESS, result } }
    function failure(errorMessages) { return { type: giftCardConstants.ADD_GIFT_CARD_FAILURE, errorMessages } }
}

function startExcelExport() {
    return dispatch => {
        dispatch(request());

        giftcardService.startExcelExport()
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.downloadId))
                        return result.downloadId;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: giftCardConstants.START_GIFT_CARD_EXCEL_EXPORT_REQUEST } }
    function success(result) { return { type: giftCardConstants.START_GIFT_CARD_EXCEL_EXPORT_SUCCESS, result } }
    function failure(errorMessages) { return { type: giftCardConstants.START_GIFT_CARD_EXCEL_EXPORT_FAILURE, errorMessages } }
}

function uploadImportFile(file) {
    return dispatch => {
        dispatch(request());

        return giftcardService.uploadImportFile(file)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result;
                    } else {
                        dispatch(success(result.result))
                        return result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                    return {errorMessages: ["Ein technischer Fehler ist aufgetreten"]}
                }
            );
    };

    function request() { return { type: giftCardConstants.UPLOAD_GIFT_CARD_IMPORT_FILE_REQUEST } }
    function success(result) { return { type: giftCardConstants.UPLOAD_GIFT_CARD_IMPORT_FILE_SUCCESS, result } }
    function failure(errorMessages) { return { type: giftCardConstants.UPLOAD_GIFT_CARD_IMPORT_FILE_FAILURE, errorMessages } }
}

function redeemGiftcard(giftcardUUID, amount) {
    return dispatch => {
        dispatch(request());

        giftcardService.redeemGiftcard(giftcardUUID, amount)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: giftCardConstants.REDEEM_GIFT_CARD_REQUEST } }
    function success(result) { return { type: giftCardConstants.REDEEM_GIFT_CARD_SUCCESS, result } }
    function failure(errorMessages) { return { type: giftCardConstants.REDEEM_GIFT_CARD_FAILURE, errorMessages } }
}

function toggleAddModal() {
    return { type: giftCardConstants.TOGGLE_ADD_MODAL };
}

function toggleImportModal() {
    return { type: giftCardConstants.TOGGLE_IMPORT_MODAL };
}

function toggleRedeemModal(giftcard) {
    return { type: giftCardConstants.TOGGLE_REDEEM_MODAL, giftcard: giftcard };
}

function toggleRedemptionModal(giftcard) {
    return { type: giftCardConstants.TOGGLE_REDEMPTION_MODAL, giftcard: giftcard };
}