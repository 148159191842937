import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { userActions } from '../_actions';

class ActivationPage extends React.Component {
    
    constructor(props) {
    super(props);

    this.state = {
        newPasswordMatch : '',
        newPassword : '',
        submitted: false,
        externalId : this.props.location.search.substr(1)
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { externalId, newPassword, newPasswordMatch } = this.state;
        const { dispatch } = this.props;
        if (externalId && newPassword && newPasswordMatch) {
            dispatch(userActions.activateAccount(externalId, newPassword, newPasswordMatch));
        }
    }

    render() {
    const { authentication } = this.props;
    const { newPasswordMatch, newPassword, submitted } = this.state;
    
    return (
        <div className="app flex-row align-items-center">
        <Container>
            <Row className="justify-content-center">
            <Col md="6">
                <CardGroup>
                <Card className="p-4">
                    <CardBody>
                    <Col xs="12" className="justify-content-center">
                        <img src="logo.png" style={{maxWidth:'60%'}} className="rounded mx-auto d-block"/><br/><br/>
                    </Col>
                    <h1>Benutzer aktivieren</h1><br/>
                    Bitte wählen Sie ein neues Passwort 
                    <ul>
                        <li>mindestens 5 Zeichen</li>
                        <li>mindestens 1 Großbuchstabe</li>
                        <li>mindestens 1 Zahl</li>
                    </ul>
                    <form name="form" onSubmit={this.handleSubmit}>
                    {authentication && authentication.errorMessage && 
                        <div className="alert alert-warning" role="alert">
                            {authentication.errorMessage}
                        </div>
                    }
                    <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="icon-lock"></i>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder="Passwort" name="newPassword" value={newPassword} onChange={this.handleChange}/>
                        {submitted && !newPassword &&
                            <div className="help-block">Passwort ist ein Pflichtfeld</div>
                        }
                    </InputGroup>
                    <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="icon-lock"></i>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder="Passwort wiederholen" name="newPasswordMatch" value={newPasswordMatch} onChange={this.handleChange}/>
                        {submitted && !newPasswordMatch &&
                            <div className="help-block">Passwort ist ein Pflichtfeld</div>
                        }
                        </InputGroup>
                    <Row>
                        <Col xs="6">
                        <Button color="primary" className="px-4 btn btn-primary" onClick={this.handleSubmit}>Passwort setzen</Button>
                        {authentication && authentication.loading &&
                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        }
                        </Col>
                    </Row>
                    </form>
                    </CardBody>
                </Card>
                </CardGroup>
            </Col>
            </Row>
        </Container>
        </div>
    );
    }
}
    
function mapStateToProps(state) {
    const { authentication } = state;
    return {
        authentication
    };
}

const connectedActivationPage = connect(mapStateToProps)(ActivationPage);
export { connectedActivationPage as ActivationPage }; 