import { authHeader } from '../_helpers';
import "isomorphic-fetch";
import { handleResponse } from './responseHandler';

export const giftcardService = {
    getGiftCards,
    getGiftCardOverview,
    addGiftCard,
    startExcelExport,
    uploadImportFile,
    redeemGiftcard,
};

function getGiftCards(name, year, month, type) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    let parameters = "?";

    if (name && name !== "") {
        parameters = parameters + "name=" + name;
    }

    if (year) {
        if (parameters !== "?") {
            parameters = parameters + "&year=" + year;
        } else {
            parameters = parameters + "year=" + year;
        }
    }

    if (month) {
        if (parameters !== "?") {
            parameters = parameters + "&month=" + month;
        } else {
            parameters = parameters + "month=" + month;
        }
    }

    if (type) {
        if (parameters !== "?") {
            parameters = parameters + "&type=" + type;
        } else {
            parameters = parameters + "type=" + type;
        }
    }

    if (parameters !== "?") {
        return window.fetch(process.env.REACT_APP_API_BASE_URL + '/giftcard' + parameters, requestOptions).then(handleResponse);
    } else {
        return window.fetch(process.env.REACT_APP_API_BASE_URL + '/giftcard', requestOptions).then(handleResponse);
    }
}

function getGiftCardOverview() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/giftcard/overview', requestOptions).then(handleResponse);
}

function addGiftCard(giftcard) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(giftcard)
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/giftcard', requestOptions).then(handleResponse);
}

function startExcelExport() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/giftcard/export', requestOptions).then(handleResponse);
}

function uploadImportFile(file) {
    let user = JSON.parse(localStorage.getItem('hmtguser'));

    let header = {
        'Authorization': 'Bearer ' + user.access_token,
    };
    const requestOptions = {
        method: 'POST',
        headers: header,
        body: file
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/giftcard/import', requestOptions).then(handleResponse);
}

function redeemGiftcard(giftcardUUID, amount) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({giftcardUUID: giftcardUUID, amount: amount})
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/giftcard/redeem', requestOptions).then(handleResponse);
}