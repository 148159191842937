import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return (<div>Loading...</div>);
}

const BudgetOverview = Loadable({
  loader: () => import('./BudgetOverview'),
  loading: Loading,
});

const CreditorOverview = Loadable({
  loader: () => import('./CreditorOverview'),
  loading: Loading,
});

const BudgetDetails = Loadable({
  loader: () => import('./BudgetDetails'),
  loading: Loading,
});

const EventOverview = Loadable({
  loader: () => import('./EventOverview'),
  loading: Loading,
});

const ForecastDetails = Loadable({
  loader: () => import('./ForecastDetails'),
  loading: Loading,
});
 
const Organization = Loadable({
  loader: () => import('./Organization'),
  loading: Loading,
});

const AddressSearch = Loadable({
  loader: () => import('./AddressSearch'),
  loading: Loading,
});

const CityTours = Loadable({
  loader: () => import('./CityTours'),
  loading: Loading,
});

const CityTourGuides = Loadable({
  loader: () => import('./CityTourGuides'),
  loading: Loading,
});

const CityTourMeetingPoints = Loadable({
  loader: () => import('./CityTourMeetingPoints'),
  loading: Loading,
});

const CityTourAttendees = Loadable({
  loader: () => import('./CityTourAttendees/CityTourAttendees'),
  loading: Loading,
});

const GiftCardOverview = Loadable({
  loader: () => import('./GiftCardOverview/GiftCardOverview'),
  loading: Loading,
});

const FireworkOverview = Loadable({
  loader: () => import('./FireworkOverview/FireworkOverview'),
  loading: Loading,
});



const routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/giftcards', name: 'Gutscheinbuch', component: GiftCardOverview },
  { path: '/firework', name: 'Feuerwerk', component: FireworkOverview },
  { path: '/budget/details', name: 'Details', component: BudgetDetails },
  { path: '/budget/forecast', name: 'Vorschau', component: ForecastDetails },
  { path: '/budget/creditors', name: 'Kreditoren', component: CreditorOverview },
  { path: '/budget', name: 'Budget Übersicht', component: BudgetOverview },
  { path: '/event', name: 'Veranstaltungen', component: EventOverview },
  { path: '/firma', name: 'Firma', component: Organization },
  { path: '/adressen', name: 'Adressen', component: AddressSearch },
  { path: '/stadtfuehrungen/teilnehmer/:id', name: 'Teilnehmer', component: CityTourAttendees },
  { path: '/stadtfuehrungen/gaestefuehrer', name: 'Gästeführer', component: CityTourGuides },
  { path: '/stadtfuehrungen/treffpunkt', name: 'Treffpunkt', component: CityTourMeetingPoints },
  { path: '/stadtfuehrungen', name: 'Stadtführungen', component: CityTours },
];

export default routes;
