import {citytoursConstants} from '../_constants';
import {citytoursService} from '../_services';
import { alertActions } from './';

export const citytoursActions = {
    setSearchData,
    toggleDeleteTourGuide,
    toggleDeleteCityTour,
    toggleDeleteBookedVisitor,

    showTourGuideDetails,

    getCityTour,
    getCityTours,
    updateCityTour,
    deleteCityTour,

    getBookedVisitors,
    updateBookedVisitor,
    deleteBookedVisitor,

    getTourGuides,
    updateTourGuide,
    deleteTourGuide,

    getAvailableCityTours,
    updateAvailableCityTour,
    deleteAvailableCityTour,

    prepareExcelExport,
    toggleExportModal,
    toggleImportModal,
    uploadCityTours,

    toggleMeetingPointModal,
    toggleDeleteMeetingPointModal,
    toggleEditMeetingPointModal,
    getMeetingPoints,
    updateMeetingPoint,
    deleteMeetingPoint,
};

function toggleExportModal() {
    return dispatch => {
        dispatch({ type: citytoursConstants.TOGGLE_EXPORT_MODAL });
    };
}

function toggleImportModal() {
    return dispatch => {
        dispatch({ type: citytoursConstants.TOGGLE_IMPORT_MODAL });
    };
}

function toggleMeetingPointModal() {
    return dispatch => {
        dispatch({ type: citytoursConstants.TOGGLE_MEETING_POINT_MODAL });
    };
}

function toggleDeleteMeetingPointModal(externalUUID) {
    return dispatch => {
        dispatch({ type: citytoursConstants.TOGGLE_DELETE_MEETING_POINT_MODAL, externalUUID: externalUUID });
    };
}

function toggleEditMeetingPointModal() {
    return dispatch => {
        dispatch({ type: citytoursConstants.TOGGLE_EDIT_MEETING_POINT_MODAL });
    };
}

function getMeetingPoints() {
    return dispatch => {
        dispatch(request());

        return citytoursService.getMeetingPoints()
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: citytoursConstants.GET_MEETING_POINTS_REQUEST } }
    function success(result) { return { type: citytoursConstants.GET_MEETING_POINTS_SUCCESS, result } }
    function failure(errorMessages) { return { type: citytoursConstants.GET_MEETING_POINTS_FAILURE, errorMessages } }
}

function updateMeetingPoint(tour) {
    return dispatch => {
        dispatch(request());

        return citytoursService.updateMeetingPoint(tour)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: citytoursConstants.UPDATE_MEETING_POINT_REQUEST } }
    function success(result) { return { type: citytoursConstants.UPDATE_MEETING_POINT_SUCCESS, result } }
    function failure(errorMessages) { return { type: citytoursConstants.UPDATE_MEETING_POINT_FAILURE, errorMessages } }
}

function deleteMeetingPoint(externalUUID) {
    return dispatch => {
        dispatch(request());

        return citytoursService.deleteMeetingPoint(externalUUID)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: citytoursConstants.DELETE_MEETING_POINT_REQUEST } }
    function success(result) { return { type: citytoursConstants.DELETE_MEETING_POINT_SUCCESS, result } }
    function failure(errorMessages) { return { type: citytoursConstants.DELETE_MEETING_POINT_FAILURE, errorMessages } }
}

function uploadCityTours(data) {
    return dispatch => {
        dispatch(request());

        return citytoursService.uploadCityToursData(data)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success("Stadtführungen wurden erfolgreich importiert"))
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return {type: citytoursConstants.IMPORT_CITY_TOURS_REQUEST}
    }

    function success(successMessage) {
        return {type: citytoursConstants.IMPORT_CITY_TOURS_SUCCESS, successMessage}
    }

    function failure(errorMessages) {
        return {type: citytoursConstants.IMPORT_CITY_TOURS_FAILURE, errorMessages}
    }
}

function prepareExcelExport(externalUUID) {
    return dispatch => {
        dispatch(request());

        return citytoursService.prepareExcelExport(externalUUID)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.downloadId))
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: citytoursConstants.PREPARE_EXCEL_EXPORT_REQUEST } }
    function success(downloadId) { return { type: citytoursConstants.PREPARE_EXCEL_EXPORT_SUCCESS, downloadId } }
    function failure(errorMessages) { return { type: citytoursConstants.PREPARE_EXCEL_EXPORT_FAILURE, errorMessages } }
}

function setSearchData(searchData) {
    return dispatch => {
        dispatch({ type: citytoursConstants.SET_SEARCH_DATA, searchData: searchData });
    };
}

function toggleDeleteTourGuide(externalUUID) {
    return dispatch => {
        dispatch({ type: citytoursConstants.TOGGLE_DELETE_TOURGUIDE, externalUUID: externalUUID });
    };
}

function toggleDeleteCityTour(externalUUID) {
    return dispatch => {
        dispatch({ type: citytoursConstants.TOGGLE_DELETE_CITY_TOUR_MODAL, externalUUID: externalUUID });
    };
}

function toggleDeleteBookedVisitor(externalUUID) {
    return dispatch => {
        dispatch({ type: citytoursConstants.TOGGLE_DELETE_BOOKED_VISITOR_MODAL, externalUUID: externalUUID });
    };
}

function showTourGuideDetails(guide) {
    return dispatch => {
        dispatch({type: citytoursConstants.TOGGLE_TOUR_GUIDE_MODAL, detailedTourGuide: guide})
    }
}

function getCityTour(externalUUID) {
    return dispatch => {
    dispatch(request());

    return citytoursService.getCityTour(externalUUID)
        .then(
            result => {
                if (result.errorMessages && result.errorMessages.length > 0) {
                    return dispatch(failure(result.errorMessages))
                } else {
                    return dispatch(success(result.result))
                }
            },
            error => {
                dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                dispatch(alertActions.error(error));
            }
        );
};
    function request() { return { type: citytoursConstants.GET_CITYTOUR_REQUEST } }
    function success(result) { return { type: citytoursConstants.GET_CITYTOUR_SUCCESS, result } }
    function failure(errorMessages) { return { type: citytoursConstants.GET_CITYTOUR_FAILURE, errorMessages } }
}

function getCityTours(startDate, endDate) {
    return dispatch => {
        dispatch(request());

        return citytoursService.getCityTours(startDate, endDate)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: citytoursConstants.GET_CITYTOURS_REQUEST } }
    function success(result) { return { type: citytoursConstants.GET_CITYTOURS_SUCCESS, result } }
    function failure(errorMessages) { return { type: citytoursConstants.GET_CITYTOURS_FAILURE, errorMessages } }
}

function updateCityTour(tour) {
    return dispatch => {
        dispatch(request());

        return citytoursService.updateCityTour(tour)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: citytoursConstants.UPDATE_CITYTOUR_REQUEST } }
    function success(result) { return { type: citytoursConstants.UPDATE_CITYTOUR_SUCCESS, result } }
    function failure(errorMessages) { return { type: citytoursConstants.UPDATE_CITYTOUR_FAILURE, errorMessages } }
}

function deleteCityTour(externalUUID) {
    return dispatch => {
        dispatch(request());

        return citytoursService.deleteCityTour(externalUUID)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: citytoursConstants.DELETE_CITYTOUR_REQUEST } }
    function success(result) { return { type: citytoursConstants.DELETE_CITYTOUR_SUCCESS, result } }
    function failure(errorMessages) { return { type: citytoursConstants.DELETE_CITYTOUR_FAILURE, errorMessages } }
}

function getBookedVisitors(tourId) {
    return dispatch => {
        dispatch(request());

        return citytoursService.getBookedVisitors(tourId)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: citytoursConstants.GET_BOOKEDVISITOR_REQUEST } }
    function success(result) { return { type: citytoursConstants.GET_BOOKEDVISITOR_SUCCESS, result } }
    function failure(errorMessages) { return { type: citytoursConstants.GET_BOOKEDVISITOR_FAILURE, errorMessages } }
}

function updateBookedVisitor(visitor) {
    return dispatch => {
        dispatch(request());

        return citytoursService.updateBookedVisitor(visitor)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: citytoursConstants.UPDATE_BOOKEDVISITOR_REQUEST } }
    function success(result) { return { type: citytoursConstants.UPDATE_BOOKEDVISITOR_SUCCESS, result } }
    function failure(errorMessages) { return { type: citytoursConstants.UPDATE_BOOKEDVISITOR_FAILURE, errorMessages } }
}

function deleteBookedVisitor(externalUUID) {
    return dispatch => {
        dispatch(request());

        return citytoursService.deleteBookedVisitor(externalUUID)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: citytoursConstants.DELETE_BOOKEDVISITOR_REQUEST } }
    function success(result) { return { type: citytoursConstants.DELETE_BOOKEDVISITOR_SUCCESS, result } }
    function failure(errorMessages) { return { type: citytoursConstants.DELETE_BOOKEDVISITOR_FAILURE, errorMessages } }
}

function getTourGuides() {
    return dispatch => {
        dispatch(request());

        return citytoursService.getTourGuides()
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: citytoursConstants.GET_TOURGUIDE_REQUEST } }
    function success(result) { return { type: citytoursConstants.GET_TOURGUIDE_SUCCESS, result } }
    function failure(errorMessages) { return { type: citytoursConstants.GET_TOURGUIDE_FAILURE, errorMessages } }
}

function updateTourGuide(guide) {
    return dispatch => {
        dispatch(request());

        return citytoursService.updateTourGuide(guide)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: citytoursConstants.UPDATE_TOURGUIDE_REQUEST } }
    function success(result) { return { type: citytoursConstants.UPDATE_TOURGUIDE_SUCCESS, result } }
    function failure(errorMessages) { return { type: citytoursConstants.UPDATE_TOURGUIDE_FAILURE, errorMessages } }
}

function deleteTourGuide(externalUUID) {
    return dispatch => {
        dispatch(request());

        return citytoursService.deleteTourGuide(externalUUID)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: citytoursConstants.DELETE_TOURGUIDE_REQUEST } }
    function success(result) { return { type: citytoursConstants.DELETE_TOURGUIDE_SUCCESS, result } }
    function failure(errorMessages) { return { type: citytoursConstants.DELETE_TOURGUIDE_FAILURE, errorMessages } }
}

function getAvailableCityTours() {
    return dispatch => {
        dispatch(request());

        return citytoursService.getAvailableCityTours()
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        if (!result.result) {
                            return dispatch(success([]))
                        }
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: citytoursConstants.GET_AVAILABLE_TOURS_REQUEST } }
    function success(result) { return { type: citytoursConstants.GET_AVAILABLE_TOURS_SUCCESS, result } }
    function failure(errorMessages) { return { type: citytoursConstants.GET_AVAILABLE_TOURS_FAILURE, errorMessages } }
}

function updateAvailableCityTour(list) {
    return dispatch => {
        dispatch(request());

        return citytoursService.updateAvailableCityTour(list)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: citytoursConstants.UPDATE_AVAILABLE_TOUR_REQUEST } }
    function success(result) { return { type: citytoursConstants.UPDATE_AVAILABLE_TOUR_SUCCESS, result } }
    function failure(errorMessages) { return { type: citytoursConstants.UPDATE_AVAILABLE_TOUR_FAILURE, errorMessages } }
}

function deleteAvailableCityTour(externalUUID) {

    if (!externalUUID || externalUUID === "") {
        return;
    }

    return dispatch => {
        dispatch(request());

        return citytoursService.deleteAvailableCityTour(externalUUID)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: citytoursConstants.DELETE_AVAILABLE_TOUR_REQUEST } }
    function success(result) { return { type: citytoursConstants.DELETE_AVAILABLE_TOUR_SUCCESS, result } }
    function failure(errorMessages) { return { type: citytoursConstants.DELETE_AVAILABLE_TOUR_FAILURE, errorMessages } }
}
