import { organizationConstants, navigationConstants } from '../_constants';
import { organizationService } from '../_services';
import { alertActions } from './';

export const organizationActions = {
    getOrganization,
    toggleAddClientModal,
    addClient
};

function getOrganization() {
    return dispatch => {
        dispatch(request());

        return organizationService.getOrganization()
            .then(
                organization => { 
                    dispatch(success(organization));
                    dispatch({type: navigationConstants.UPDATE_NAVIGATION_ITEMS, allowedApplications : organization.currentUser.allowedApplications});
                    return organization;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                    return error;
                }
            );
    };

    function request(organization) { return { type: organizationConstants.GETORGANIZATION_REQUEST, organization } }
    function success(organization) { return { type: organizationConstants.GETORGANIZATION_SUCCESS, organization  } }
    function failure(error) { return { type: organizationConstants.GETORGANIZATION_FAILURE, error } }
}
function toggleAddClientModal() {
    return dispatch => {
        dispatch({ type: organizationConstants.TOGGLE_ADD_CLIENT_MODAL });
    };

}

function addClient(clientNumber) {
    return dispatch => {
        dispatch(request());

        organizationService.addClientNumber(clientNumber)
            .then(
                organization => { 
                    dispatch(success(organization));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: organizationConstants.ADD_CLIENT_REQUEST } }
    function success(organization) { return { type: organizationConstants.ADD_CLIENT_SUCCESS, organization  } }
    function failure(error) { return { type: organizationConstants.ADD_CLIENT_FAILURE, error } }
}