import { userConstants } from '../_constants';
import { navigationConstants } from '../_constants';

import { userService } from '../_services';
import { alertActions, organizationActions } from './';
import { history } from '../_helpers';

export const userActions = {
    login,
    logout,
    register,
    toggleEditUserModal,
    toggleDeactivateModal,
    deactivateUser,
    activateUser,
    activateAccount,
    updateUser,
    setUpResetPassword,
    toggleForgetPasswordModal,
    resetPassword,
    toggleRetirement,
    toggleRetirementModal
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch({type:userConstants.ADD_ADDITIONAL_USER_DATA, 'user' : JSON.parse(localStorage.getItem('hmtguser'))});
                    dispatch({type:navigationConstants.UPDATE_NAVIGATION_ITEMS, allowedApplications : user.allowedApplications});
                    history.push('/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                response => {
                    if (response.result) {
                        dispatch(success(response.result));
                        dispatch(alertActions.success('Benutzer angelegt'));
                        dispatch(organizationActions.getOrganization());
                    }
                    if (response.errorMessages) {
                        dispatch(failure(response.errorMessages[0]));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function toggleEditUserModal(mode) {
    return dispatch => {
        dispatch({ type: userConstants.TOGGLE_EDIT_USER_MODAL, mode: mode});
    };
}

function toggleDeactivateModal(user) {
    return dispatch => {
        dispatch(alertActions.clear());
        dispatch({ type: userConstants.TOGGLE_DEACTIVATE_MODAL, userItem:user});
    };
}

function deactivateUser(user) {
    return dispatch => {

        return userService.deactivateUser(user)
            .then(
                () => {
                    dispatch({type:userConstants.DEACTIVATE_SUCCESSFUL})
                },
                error => {
                    dispatch(alertActions.error(error));
                }
            )
    };
}

function activateUser(user) {
    return dispatch => {

        return userService.activateUser(user)
            .then(
                () => {
                    dispatch({type:userConstants.ACTIVATE_SUCCESSFUL})
                },
                error => {
                    dispatch(alertActions.error(error));
                }
            )
    };
}

function activateAccount(externalId, newPassword, newPasswordMatch) {
    return dispatch => {
        dispatch(request());
    
        userService.activateAccount(externalId, newPassword, newPasswordMatch)
            .then(
                result => {
                    if(result.errorMessages && result.errorMessages.length>0){
                        dispatch(failure(result.errorMessages[0]));
                        dispatch(alertActions.error(result.errorMessages[0]));
                    }else{
                        dispatch(success()).then(
                            history.push("/login")
                        )
                    }
                },
                error => {
                        dispatch(failure("Es ist ein technischer Fehler aufgetreten."));
                        dispatch(alertActions.error(error));
                }
            );
    };
    
    function request() { return { type: userConstants.ACTIVATE_USER_ACCOUNT_REQUEST } }
    function success() { return { type: userConstants.ACTIVATE_USER_ACCOUNT_SUCCESS }}
    function failure(error) { return { type: userConstants.ACTIVATE_USER_ACCOUNT_FAILURE, error } }
}

function updateUser(user) {
    return dispatch => {
        dispatch(request());

        return userService.updateUser(user)
            .then(
               result => {
                    if (result.errorMessages && result.errorMessages.length>0){
                        dispatch(failure(result.errorMessages[0]));
                    } else {
                        dispatch(success(result));
                        return result;
                    }
                },
                error => {
                    dispatch(failure("Es ist ein technischer Fehler aufgetreten."));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: userConstants.UPDATE_USER_REQUEST } }
    function success() { return { type: userConstants.UPDATE_USER_SUCCESS }}
    function failure(error) { return { type: userConstants.UPDATE_USER_FAILURE, error } }
}

function setUpResetPassword(email) {
    return dispatch => {
        dispatch(request(email));

        return userService.setUpResetPassword(email)
            .then(
                result => {
                    if (result.errorMessages) {
                        dispatch(failure(result.errorMessages[0]));
                        dispatch(alertActions.error(result.errorMessages[0]));
                    }else {
                        dispatch(success(email));
                        dispatch(alertActions.success('Mail wurde versandt.'));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(email) { return { type: userConstants.SETUPRESETPASSWORD_REQUEST, email} }
    function success(email) { return { type: userConstants.SETUPRESETPASSWORD_SUCCESS, email } }
    function failure(error) { return { type: userConstants.SETUPRESETPASSWORD_FAILURE, error } }
}

function resetPassword(externalId, newPassword, newPasswordMatch) {
    return dispatch => {
        dispatch(request());

        userService.resetPassword(externalId, newPassword, newPasswordMatch)
            .then(
                result => {
                    if(result.errorMessages){
                        dispatch(failure(result.errorMessages[0]));
                        dispatch(alertActions.error(result.errorMessages[0]));
                    } else {
                        dispatch(success())
                        history.push("/login")
                    }
                })
    }
    
    function request() { return { type: userConstants.RESETPASSWORD_REQUEST } }
    function success() { return { type: userConstants.RESETPASSWORD_SUCCESS }}
    function failure(error) { return { type: userConstants.RESETPASSWORD_FAILURE, error } }
};

function toggleRetirement(userId) {
    return dispatch => {
        dispatch(request());

        return userService.toggleRetirement(userId)
            .then(
                result => {
                    if(result.errorMessages && result.errorMessages.length>0){
                        dispatch(failure(result.errorMessages[0]));
                    }else{
                        dispatch(organizationActions.getOrganization()).then(() => {
                            dispatch(success(result));
                            return result;
                        });
                    }
                },
                error => {
                    dispatch(failure("Es ist ein technischer Fehler aufgetreten."));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: userConstants.TOGGLE_USER_RETIREMENT_REQUEST} }
    function success(result) { return { type: userConstants.TOGGLE_USER_RETIREMENT_SUCCESS, result } }
    function failure(error) { return { type: userConstants.TOGGLE_USER_RETIREMENT_FAILURE, error } }
}

function toggleRetirementModal(user) {
    return dispatch => {
        dispatch({ type: userConstants.TOGGLE_USER_RETIREMENT_MODAL, user: user});
    }
}

function toggleForgetPasswordModal() {
    return dispatch => {
        dispatch(alertActions.clear());
        dispatch({ type: userConstants.TOGGLEFORGETPASSWORDMODAL});
    }
}