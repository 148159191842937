export const addressConstants = {

    TOGGLE_MODAL_IMPORT : 'TOGGLE_MODAL_IMPORT',
    HANDLE_EDIT : "HANDLE_EDIT",

    UPLOAD_ADDRESS_FILE_REQUEST : 'UPLOAD_ADDRESS_FILE_REQUEST',
    UPLOAD_ADDRESS_FILE_SUCCESS : 'UPLOAD_ADDRESS_FILE_SUCCESS',
    UPLOAD_ADDRESS_FILE_FAILURE : 'UPLOAD_ADDRESS_FILE_FAILURE',

    ADD_ADDRESS_DATA_REQUEST: 'ADD_ADDRESS_DATA_REQUEST',
    ADD_ADDRESS_DATA_SUCCESS: 'ADD_ADDRESS_DATA_SUCCESS',
    ADD_ADDRESS_DATA_FAILURE: 'ADD_ADDRESS_DATA_FAILURE',

    UPDATE_ADDRESS_REQUEST : 'UPDATE_ADDRESS_REQUEST',
    UPDATE_ADDRESS_SUCCESS : 'UPDATE_ADDRESS_SUCCESS',
    UPDATE_ADDRESS_FAILURE : 'UPDATE_ADDRESS_FAILURE',

    GET_ADDRESSES_REQUEST : 'GET_ADDRESSES_REQUEST',
    GET_ADDRESSES_SUCCESS: 'GET_ADDRESSES_SUCCESS',
    GET_ADDRESSES_FAILURE : 'GET_ADDRESSES_FAILURE',

    GET_SEARCH_INFO_DATA_REQUEST : 'GET_SEARCH_INFO_DATA_REQUEST',
    GET_SEARCH_INFO_DATA_SUCCESS : 'GET_SEARCH_INFO_DATA_SUCCESS',
    GET_SEARCH_INFO_DATA_FAILURE : 'GET_SEARCH_INFO_DATA_FAILURE',

    SEARCH_REQUEST : 'SEARCH_REQUEST',
    SEARCH_REQUEST_SUCCESS : 'SEARCH_REQUEST_SUCCESS',
    SEARCH_REQUEST_FAILURE : 'SEARCH_REQUEST_FAILURE',

};
