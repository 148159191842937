export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    REGISTER_SHOPIFY_REQUEST : 'REGISTER_SHOPIFY_REQUEST',
    REGISTER_SHOPIFY_SUCCESS : 'REGISTER_SHOPIFY_SUCCESS',
    REGISTER_SHOPIFY_FAILURE : 'REGISTER_SHOPIFY_FAILURE',

    FETCH_INITIAL_SHOPIFY_DATA_SUCCESS : 'FETCH_INITIAL_SHOPIFY_DATA_SUCCESS',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    
    ADD_ADDITIONAL_USER_DATA : 'ADD_ADDITIONAL_USER_DATA',

    TOGGLE_EDIT_USER_MODAL: 'TOGGLE_EDIT_USER_MODAL',
    TOGGLE_DEACTIVATE_MODAL: 'TOGGLE_DEACTIVATE_MODAL',
    DEACTIVATE_SUCCESSFUL:'DEACTIVATE_SUCCESSFUL',
    LOGOUT: 'USERS_LOGOUT',

    ACTIVATE_USER_ACCOUNT_REQUEST: 'ACTIVATE_USER_ACCOUNT_REQUEST',
    ACTIVATE_USER_ACCOUNT_SUCCESS: 'ACTIVATE_USER_ACCOUNT_SUCCESS',
    ACTIVATE_USER_ACCOUNT_FAILURE: 'ACTIVATE_USER_ACCOUNT_FAILURE',

    ACTIVATE_SUCCESSFUL : 'ACTIVATE_SUCCESSFUL',

    UPDATE_USER_REQUEST : 'UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS : 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

    TOGGLEFORGETPASSWORDMODAL: 'TOGGLEFORGETPASSWORDMODAL',
    SETUPRESETPASSWORD_REQUEST: 'SETUPRESETPASSWORD_REQUEST',
    SETUPRESETPASSWORD_SUCCESS: 'SETUPRESETPASSWORD_SUCCESS',
    SETUPRESETPASSWORD_FAILURE: 'SETUPRESETPASSWORD_FAILURE',
    CLEAR_RESET_SUCCESS_MESSAGE: 'CLEAR_RESET_SUCCESS_MESSAGE',

    RESETPASSWORD_REQUEST: 'RESETPASSWORD_REQUEST',
    RESETPASSWORD_SUCCESS: 'RESETPASSWORD_SUCCESS',
    RESETPASSWORD_FAILURE: 'RESETPASSWORD_FAILURE',

    TOGGLE_USER_RETIREMENT_REQUEST : 'TOGGLE_USER_RETIREMENT_REQUEST',
    TOGGLE_USER_RETIREMENT_SUCCESS : 'TOGGLE_USER_RETIREMENT_SUCCESS',
    TOGGLE_USER_RETIREMENT_FAILURE : 'TOGGLE_USER_RETIREMENT_FAILURE',

    TOGGLE_USER_RETIREMENT_MODAL : 'TOGGLE_USER_RETIREMENT_MODAL'
};
