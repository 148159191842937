export const giftCardConstants = {
    GET_GIFT_CARDS_REQUEST : 'GET_GIFT_CARDS_REQUEST',
    GET_GIFT_CARDS_SUCCESS : 'GET_GIFT_CARDS_SUCCESS',
    GET_GIFT_CARDS_FAILURE : 'GET_GIFT_CARDS_FAILURE',

    GET_GIFT_CARD_OVERVIEW_REQUEST : 'GET_GIFT_CARD_OVERVIEW_REQUEST',
    GET_GIFT_CARD_OVERVIEW_SUCCESS : 'GET_GIFT_CARD_OVERVIEW_SUCCESS',
    GET_GIFT_CARD_OVERVIEW_FAILURE : 'GET_GIFT_CARD_OVERVIEW_FAILURE',

    ADD_GIFT_CARD_REQUEST : 'ADD_GIFT_CARD_REQUEST',
    ADD_GIFT_CARD_SUCCESS : 'ADD_GIFT_CARD_SUCCESS',
    ADD_GIFT_CARD_FAILURE : 'ADD_GIFT_CARD_FAILURE',

    START_GIFT_CARD_EXCEL_EXPORT_REQUEST : 'START_GIFT_CARD_EXCEL_EXPORT_REQUEST',
    START_GIFT_CARD_EXCEL_EXPORT_SUCCESS : 'START_GIFT_CARD_EXCEL_EXPORT_SUCCESS',
    START_GIFT_CARD_EXCEL_EXPORT_FAILURE : 'START_GIFT_CARD_EXCEL_EXPORT_FAILURE',

    UPLOAD_GIFT_CARD_IMPORT_FILE_REQUEST : 'UPLOAD_GIFT_CARD_IMPORT_FILE_REQUEST',
    UPLOAD_GIFT_CARD_IMPORT_FILE_SUCCESS : 'UPLOAD_GIFT_CARD_IMPORT_FILE_SUCCESS',
    UPLOAD_GIFT_CARD_IMPORT_FILE_FAILURE : 'UPLOAD_GIFT_CARD_IMPORT_FILE_FAILURE',

    REDEEM_GIFT_CARD_REQUEST : 'REDEEM_GIFT_CARD_REQUEST',
    REDEEM_GIFT_CARD_SUCCESS : 'REDEEM_GIFT_CARD_SUCCESS',
    REDEEM_GIFT_CARD_FAILURE : 'REDEEM_GIFT_CARD_FAILURE',

    TOGGLE_ADD_MODAL : 'TOGGLE_ADD_MODAL',
    TOGGLE_IMPORT_MODAL : 'TOGGLE_IMPORT_MODAL',
    TOGGLE_REDEEM_MODAL : 'TOGGLE_REDEEM_MODAL',
    TOGGLE_REDEMPTION_MODAL : 'TOGGLE_REDEMPTION_MODAL',

    RESET_EXCEL_LINK : 'RESET_EXCEL_LINK',


};
