import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('hmtguser'));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
      case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };

      case userConstants.ADD_ADDITIONAL_USER_DATA:
      return {
        ...state,
        user: action.user
      }

      case userConstants.FETCH_INITIAL_SHOPIFY_DATA_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };

      case userConstants.ACTIVATE_USER_ACCOUNT_REQUEST : 
      {
        return {
          ...state,
          loading : true,
          errorMessage : null
        };
      };

      case userConstants.ACTIVATE_USER_ACCOUNT_SUCCESS : 
      {
        return {
          ...state,
          loading : false,
          errorMessage : null
        };
      };

      case userConstants.ACTIVATE_USER_ACCOUNT_FAILURE : 
      {
        return {
          ...state,
          loading : false,
          errorMessage : action.error
        };
      };


      
      case userConstants.LOGIN_FAILURE:
      return {
          ...state,
          loggingIn: false,
          loading: false,
          loggedIn: false,
          errorMessages: action.error
      };
    case userConstants.LOGOUT:
      return {};

    case userConstants.TOGGLEFORGETPASSWORDMODAL:
      return {
          ...state,
          forgetPasswordModal: !state.forgetPasswordModal,
          errorMessages: null
      };

      case userConstants.SETUPRESETPASSWORD_REQUEST:
      return {
          ...state,
          loading: true,
          successMessage : null,
          errorMessages: null
      };
      
    case userConstants.SETUPRESETPASSWORD_SUCCESS:
      return {
          ...state,
          loading: false,
          forgetPasswordModal: false,
          successMessage : 'Mail wurde verschickt'
      };
  case userConstants.SETUPRESETPASSWORD_FAILURE:
      return {
          ...state,
          loading: false,
          errorMessages: action.error
      };
  case userConstants.CLEAR_RESET_SUCCESS_MESSAGE:
      return {
          ...state,
          loading: false,
          updateModal: false,
          successMessage : null
      };
    
      case userConstants.RESETPASSWORD_REQUEST :
          return {
              ...state,
              loading: true,
              errorMessage: null
          };

      case userConstants.RESETPASSWORD_SUCCESS :
          return {
              ...state,
              loading: false,
              errorMessage: null
          };

      case userConstants.RESETPASSWORD_FAILURE :
          return {
              ...state,
              loading: false,
              errorMessage: action.error
          };

    default:
      return state
  }
}