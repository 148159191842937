import { authHeader } from '../_helpers';
import "isomorphic-fetch";
import { handleResponse } from './responseHandler';

export const fireworkService = {
    getFireworks,
    saveFirework,
    getVVK,
    saveVVK,
    uploadFireworkImport,
    startExcelExport,
    getAnnualReports,
    saveAnnualReports,
    getManualTickets,
    saveManualTickets,
    getStocktaking,
    saveStocktaking,
    getSalesStatistics,
    saveSalesStatistics,
    getDuties,
    saveDuties
};

function getFireworks(year) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    if (year) {
        return window.fetch(process.env.REACT_APP_API_BASE_URL + '/firework/' + year, requestOptions).then(handleResponse);
    } else {
        return window.fetch(process.env.REACT_APP_API_BASE_URL + '/firework', requestOptions).then(handleResponse);
    }
}

function saveFirework(firework) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(firework)
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/firework', requestOptions).then(handleResponse);
}

function getVVK() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/firework/vvk', requestOptions).then(handleResponse);
}

function saveVVK(vvks) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(vvks)
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/firework/vvk', requestOptions).then(handleResponse);
}

function uploadFireworkImport(file) {
    let user = JSON.parse(localStorage.getItem('hmtguser'));
    let header = {
        'Authorization': 'Bearer ' + user.access_token,
    };
    const requestOptions = {
        method: 'POST',
        headers: header,
        body: file
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/firework/import', requestOptions).then(handleResponse);
}

function startExcelExport() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/firework/export', requestOptions).then(handleResponse);
}

function getAnnualReports() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/firework/annualreports', requestOptions).then(handleResponse);
}

function saveAnnualReports(reports) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(reports)
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/firework/annualreports', requestOptions).then(handleResponse);
}

function getManualTickets(fireworkUUID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/firework/manualtickets/' + fireworkUUID, requestOptions).then(handleResponse);
}

function saveManualTickets(manualTickets) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(manualTickets)
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/firework/manualtickets', requestOptions).then(handleResponse);
}

function getStocktaking(fireworkUUID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/firework/stocktaking/' + fireworkUUID, requestOptions).then(handleResponse);
}

function saveStocktaking(fireworkUUID, stocktaking) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(stocktaking)
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/firework/stocktaking', requestOptions).then(handleResponse);
}

function getSalesStatistics(fireworkUUID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/firework/salesstatistics/' + fireworkUUID, requestOptions).then(handleResponse);
}

function saveSalesStatistics(salesStatistics) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(salesStatistics)
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/firework/salesstatistics', requestOptions).then(handleResponse);
}

function getDuties(year) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/firework/duties/' + year, requestOptions).then(handleResponse);
}

function saveDuties(duties) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(duties)
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/firework/duties', requestOptions).then(handleResponse);
}
