import { fireworkConstants } from '../_constants';

export function firework(state = {}, action) {
  let fireworks;
  switch (action.type) {
    case fireworkConstants.GET_ALL_FIREWORKS_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessages: null,
        fireworks: null,
        modalErrorMessages: null,
        editFireworkModal: false,
        importModal: false,
      }

    case fireworkConstants.GET_ALL_FIREWORKS_SUCCESS:
      return {
        ...state,
        loading: false,
        fireworks: action.result
      }

    case fireworkConstants.GET_ALL_FIREWORKS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessages: action.errorMessages
      }

    case fireworkConstants.SAVE_FIREWORK_REQUEST:
      return {
        ...state,
        saveLoading: true,
        modalErrorMessages: null
      }

    case fireworkConstants.SAVE_FIREWORK_SUCCESS:
      return {
        ...state,
        saveLoading: false,
      }

    case fireworkConstants.SAVE_FIREWORK_FAILURE:
      return {
        ...state,
        saveLoading: false,
        modalErrorMessages: action.errorMessages
      }

    case fireworkConstants.GET_VVK_REQUEST:
      return {
        ...state,
        modalLoading: true,
        modalErrorMessages: null,
        vvks: null,
      }

    case fireworkConstants.GET_VVK_SUCCESS:
      return {
        ...state,
        modalLoading: false,
        vvks: action.result
      }

    case fireworkConstants.GET_VVK_FAILURE:
      return {
        ...state,
        modalLoading: false,
        modalErrorMessages: action.errorMessages
      }

    case fireworkConstants.SAVE_VVK_REQUEST:
      return {
        ...state,
        modalSaveLoading: true,
        modalErrorMessages: null
      }

    case fireworkConstants.SAVE_VVK_SUCCESS:
      return {
        ...state,
        modalSaveLoading: true,
        modalErrorMessages: null
      }

    case fireworkConstants.SAVE_VVK_FAILURE:
      return {
        ...state,
        modalSaveLoading: false,
        modalErrorMessages: action.errorMessages
      }

    case fireworkConstants.UPLOAD_FIREWORK_IMPORT_FILE_REQUEST:
      return {
        ...state,
        importLoading: true,
        modalErrorMessages: null
      }

    case fireworkConstants.UPLOAD_FIREWORK_IMPORT_FILE_SUCCESS:
      return {
        ...state,
        importLoading: false,
        importModal: false,
        overview: null,
        giftcards: null
      }

    case fireworkConstants.UPLOAD_FIREWORK_IMPORT_FILE_FAILURE:
      return {
        ...state,
        importLoading: false,
        modalErrorMessages: action.errorMessages
      }

    case fireworkConstants.START_FIREWORK_EXCEL_EXPORT_REQUEST:
      return {
        ...state,
        exportLoading: true,
        errorMessages: null,
      }
    case fireworkConstants.START_FIREWORK_EXCEL_EXPORT_SUCCESS:
      return {
        ...state,
        exportLoading: false,
        excelDownloadLink: action.result
      }

    case fireworkConstants.START_FIREWORK_EXCEL_EXPORT_FAILURE:
      return {
        ...state,
        exportLoading: false,
        errorMessages: action.errorMessages,
      }

    case fireworkConstants.TOGGLE_IMPORT_MODAL:
      return {
        ...state,
        importModal: !state.importModal,
        modalErrorMessages: null
      }

    case fireworkConstants.TOGGLE_EDIT_FIREWORK_MODEL:
      return {
        ...state,
        editFireworkModal: !state.editFireworkModal,
        modalErrorMessages: null,
      }

    case fireworkConstants.TOGGLE_VVK_MODAL:
      return {
        ...state,
        vvkModal: !state.vvkModal,
        modalErrorMessages: null,
      }

    case fireworkConstants.TOGGLE_ANNUAL_REPORTS_MODAL:
      return {
        ...state,
        annualReportsModal: !state.annualReportsModal,
        modalErrorMessages: null
      }

    case fireworkConstants.TOGGLE_MANUAL_TICKETS_MODAL:
      return {
        ...state,
        manualTicketsModal: !state.manualTicketsModal,
        modalErrorMessages: null,
        selectedFirework: action.firework
      }

    case fireworkConstants.TOGGLE_STOCKTAKING_MODAL:
      return {
        ...state,
        stocktakingModal: !state.stocktakingModal,
        modalErrorMessages: null,
        selectedFirework: action.firework
      }

    case fireworkConstants.TOGGLE_SALES_STATISTICS_MODAL:
      return {
        ...state,
        salesStatisticsModal: !state.salesStatisticsModal,
        modalErrorMessages: null,
        selectedFirework: action.firework,
      }

    case fireworkConstants.TOGGLE_DUTIES_MODAL:
      return {
        ...state,
        dutiesModal: !state.dutiesModal,
        modalErrorMessages: null,
      }

    case fireworkConstants.GET_MANUAL_TICKETS_REQUEST:
      return {
        ...state,
        modalLoading: true,
        modalErrorMessages: null,
      }

    case fireworkConstants.GET_MANUAL_TICKETS_SUCCESS:
      return {
        ...state,
        modalLoading: false,
      }

    case fireworkConstants.GET_MANUAL_TICKETS_FAILURE:
      return {
        ...state,
        modalLoading: false,
        modalErrorMessages: action.errorMessages,
      }

    case fireworkConstants.SAVE_MANUAL_TICKETS_REQUEST:
      return {
        ...state,
        modalSaveLoading: true,
        modalErrorMessages: null,
      }

    case fireworkConstants.SAVE_MANUAL_TICKETS_SUCCESS:
      return {
        ...state,
        modalSaveLoading: false,
      }

    case fireworkConstants.SAVE_MANUAL_TICKETS_FAILURE:
      return {
        ...state,
        modalSaveLoading: false,
        modalErrorMessages: action.errorMessages,
      }

    case fireworkConstants.GET_STOCKTAKING_REQUEST:
      return {
        ...state,
        modalLoading: true,
        modalErrorMessages: null
      }

    case fireworkConstants.GET_STOCKTAKING_SUCCESS:
      return {
        ...state,
        modalLoading: false,
      }

    case fireworkConstants.GET_STOCKTAKING_FAILURE:
      return {
        ...state,
        modalLoading: false,
        modalErrorMessages: action.errorMessages
      }

    case fireworkConstants.SAVE_STOCKTAKING_REQUEST:
      return {
        ...state,
        modalSaveLoading: true,
        modalErrorMessages: null
      }

    case fireworkConstants.SAVE_STOCKTAKING_SUCCESS:
      return {
        ...state,
        modalSaveLoading: false,
      }

    case fireworkConstants.SAVE_STOCKTAKING_FAILURE:
      return {
        ...state,
        modalSaveLoading: false,
        modalErrorMessages: action.errorMessages
      }

    case fireworkConstants.GET_SALES_STATISTICS_REQUEST:
      return {
        ...state,
        modalLoading: true,
        modalErrorMessages: null
      }

    case fireworkConstants.GET_SALES_STATISTICS_SUCCESS:
      return {
        ...state,
        modalLoading: false,
      }

    case fireworkConstants.GET_SALES_STATISTICS_FAILURE:
      return {
        ...state,
        modalLoading: false,
        modalErrorMessages: action.errorMessages
      }

    case fireworkConstants.SAVE_SALES_STATISTICS_REQUEST:
      return {
        ...state,
        modalSaveLoading: true,
        modalErrorMessages: null
      }

    case fireworkConstants.SAVE_SALES_STATISTICS_SUCCESS:
      return {
        ...state,
        modalSaveLoading: false,
      }

    case fireworkConstants.SAVE_SALES_STATISTICS_FAILURE:
      return {
        ...state,
        modalSaveLoading: false,
        modalErrorMessages: action.errorMessages
      }

    case fireworkConstants.GET_ANNUAL_REPORTS_REQUEST:
      return {
        ...state,
        modalLoading: true,
        modalErrorMessages: null
      }

    case fireworkConstants.GET_ANNUAL_REPORTS_SUCCESS:
      return {
        ...state,
        modalLoading: false,
      }

    case fireworkConstants.GET_ANNUAL_REPORTS_FAILURE:
      return {
        ...state,
        modalLoading: false,
        modalErrorMessages: action.errorMessages
      }

    case fireworkConstants.SAVE_ANNUAL_REPORTS_REQUEST:
      return {
        ...state,
        modalSaveLoading: true,
        modalErrorMessages: null
      }

    case fireworkConstants.SAVE_ANNUAL_REPORTS_SUCCESS:
      return {
        ...state,
        modalSaveLoading: false,
      }

    case fireworkConstants.SAVE_ANNUAL_REPORTS_FAILURE:
      return {
        ...state,
        modalSaveLoading: false,
        modalErrorMessages: action.errorMessages
      }

    case fireworkConstants.GET_DUTIES_REQUEST:
      return {
        ...state,
        modalLoading: true,
        modalErrorMessages: null,
        duties: null
      }

    case fireworkConstants.GET_DUTIES_SUCCESS:
      return {
        ...state,
        modalLoading: false,
        duties: action.result
      }
    case fireworkConstants.GET_DUTIES_FAILURE:
      return {
        ...state,
        modalLoading: false,
        modalErrorMessages: action.errorMessage
      }

    case fireworkConstants.SAVE_DUTIES_REQUEST:
      return {
        ...state,
        modalSaveLoading: true,
        modalErrorMessages: null,
      }

    case fireworkConstants.SAVE_DUTIES_SUCCESS:
      return {
        ...state,
        modalSaveLoading: false,
        duties: null,
        dutiesModal: false
      }

    case fireworkConstants.SAVE_DUTIES_FAILURE:
      return {
        ...state,
        modalSaveLoading: false,
        modalErrorMessages: action.errorMessage,
      }




    case fireworkConstants.SET_DUMMY_PROPS:
      return {
        ...state,
        ...action.props
      }

    default:
      return state
  }
}