import {fireworkConstants} from '../_constants';
import {fireworkService} from "../_services";

export const fireworkActions = {
    getFireworks,
    saveFirework,
    toggleEditFireworkModal,

    getVVK,
    saveVVK,
    toggleVVKModal,

    uploadFireworkImport,
    startExcelExport,
    toggleImportModal,


    getAnnualReports,
    saveAnnualReports,
    toggleAnnualReportsModal,

    toggleManualTicketsModal,
    getManualTickets,
    saveManualTickets,

    toggleStocktakingModal,
    getStocktaking,
    saveStocktaking,

    toggleSalesStatistics,
    getSalesStatistics,
    saveSalesStatistics,

    toggleDutiesModal,
    getDuties,
    saveDuties,

    setDummyProps
};

function getFireworks(year) {
    return dispatch => {
        dispatch(request());

        return fireworkService.getFireworks(year)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: fireworkConstants.GET_ALL_FIREWORKS_REQUEST } }
    function success(result) { return { type: fireworkConstants.GET_ALL_FIREWORKS_SUCCESS, result } }
    function failure(errorMessages) { return { type: fireworkConstants.GET_ALL_FIREWORKS_FAILURE, errorMessages } }
}

function saveFirework(firework) {
    return dispatch => {
        dispatch(request());

        return fireworkService.saveFirework(firework)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: fireworkConstants.SAVE_FIREWORK_REQUEST } }
    function success(result) { return { type: fireworkConstants.SAVE_FIREWORK_SUCCESS, result } }
    function failure(errorMessages) { return { type: fireworkConstants.SAVE_FIREWORK_FAILURE, errorMessages } }
}

function getVVK() {
    return dispatch => {
        dispatch(request());

        return fireworkService.getVVK()
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: fireworkConstants.GET_VVK_REQUEST } }
    function success(result) { return { type: fireworkConstants.GET_VVK_SUCCESS, result } }
    function failure(errorMessages) { return { type: fireworkConstants.GET_VVK_FAILURE, errorMessages } }
}

function saveVVK(vvks) {
    return dispatch => {
        dispatch(request());

        return fireworkService.saveVVK(vvks)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: fireworkConstants.GET_VVK_REQUEST } }
    function success(result) { return { type: fireworkConstants.GET_VVK_SUCCESS, result } }
    function failure(errorMessages) { return { type: fireworkConstants.GET_VVK_FAILURE, errorMessages } }
}

function uploadFireworkImport(data) {
    return dispatch => {
        dispatch(request());

        return fireworkService.uploadFireworkImport(data)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: fireworkConstants.UPLOAD_FIREWORK_IMPORT_FILE_REQUEST } }
    function success(result) { return { type: fireworkConstants.UPLOAD_FIREWORK_IMPORT_FILE_SUCCESS, result } }
    function failure(errorMessages) { return { type: fireworkConstants.UPLOAD_FIREWORK_IMPORT_FILE_FAILURE, errorMessages } }
}

function startExcelExport() {
    return dispatch => {
        dispatch(request());

        fireworkService.startExcelExport()
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.downloadId))
                        return result.downloadId;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: fireworkConstants.START_FIREWORK_EXCEL_EXPORT_REQUEST } }
    function success(result) { return { type: fireworkConstants.START_FIREWORK_EXCEL_EXPORT_SUCCESS, result } }
    function failure(errorMessages) { return { type: fireworkConstants.START_FIREWORK_EXCEL_EXPORT_FAILURE, errorMessages } }
}

function getAnnualReports() {
    return dispatch => {
        dispatch(request());

        return fireworkService.getAnnualReports()
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: fireworkConstants.GET_ANNUAL_REPORTS_REQUEST } }
    function success(result) { return { type: fireworkConstants.GET_ANNUAL_REPORTS_SUCCESS, result } }
    function failure(errorMessages) { return { type: fireworkConstants.GET_ANNUAL_REPORTS_FAILURE, errorMessages } }
}

function saveAnnualReports(reports) {
    return dispatch => {
        dispatch(request());

        return fireworkService.saveAnnualReports(reports)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: fireworkConstants.SAVE_ANNUAL_REPORTS_REQUEST } }
    function success(result) { return { type: fireworkConstants.SAVE_ANNUAL_REPORTS_SUCCESS, result } }
    function failure(errorMessages) { return { type: fireworkConstants.SAVE_ANNUAL_REPORTS_FAILURE, errorMessages } }
}

function getManualTickets(fireworkUUID) {
    return dispatch => {
        dispatch(request());

        return fireworkService.getManualTickets(fireworkUUID)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: fireworkConstants.GET_MANUAL_TICKETS_REQUEST } }
    function success(result) { return { type: fireworkConstants.GET_MANUAL_TICKETS_SUCCESS, result } }
    function failure(errorMessages) { return { type: fireworkConstants.GET_MANUAL_TICKETS_FAILURE, errorMessages } }
}

function saveManualTickets(manualTickets) {
    return dispatch => {
        dispatch(request());

        return fireworkService.saveManualTickets(manualTickets)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: fireworkConstants.SAVE_MANUAL_TICKETS_REQUEST } }
    function success(result) { return { type: fireworkConstants.SAVE_MANUAL_TICKETS_SUCCESS, result } }
    function failure(errorMessages) { return { type: fireworkConstants.SAVE_MANUAL_TICKETS_FAILURE, errorMessages } }
}

function getStocktaking(fireworkUUID) {
    return dispatch => {
        dispatch(request());

        return fireworkService.getStocktaking(fireworkUUID)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: fireworkConstants.GET_STOCKTAKING_REQUEST } }
    function success(result) { return { type: fireworkConstants.GET_STOCKTAKING_SUCCESS, result } }
    function failure(errorMessages) { return { type: fireworkConstants.GET_STOCKTAKING_FAILURE, errorMessages } }
}

function saveStocktaking(fireworkUUID, stocktaking) {
    return dispatch => {
        dispatch(request());

        return fireworkService.saveStocktaking(fireworkUUID, stocktaking)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: fireworkConstants.SAVE_STOCKTAKING_REQUEST } }
    function success(result) { return { type: fireworkConstants.SAVE_STOCKTAKING_SUCCESS, result } }
    function failure(errorMessages) { return { type: fireworkConstants.SAVE_STOCKTAKING_FAILURE, errorMessages } }
}

function getSalesStatistics(fireworkUUID) {
    return dispatch => {
        dispatch(request());

        return fireworkService.getSalesStatistics(fireworkUUID)
            .then(
                result => {
                    console.log("getSalesStatistics actions result");
                    console.log(result);
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: fireworkConstants.GET_SALES_STATISTICS_REQUEST } }
    function success(result) { return { type: fireworkConstants.GET_SALES_STATISTICS_SUCCESS, result } }
    function failure(errorMessages) { return { type: fireworkConstants.GET_SALES_STATISTICS_FAILURE, errorMessages } }
}

function saveSalesStatistics(salesStatistics) {
    return dispatch => {
        dispatch(request());

        return fireworkService.saveSalesStatistics(salesStatistics)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: fireworkConstants.SAVE_SALES_STATISTICS_REQUEST } }
    function success(result) { return { type: fireworkConstants.SAVE_SALES_STATISTICS_SUCCESS, result } }
    function failure(errorMessages) { return { type: fireworkConstants.SAVE_SALES_STATISTICS_FAILURE, errorMessages } }
}

function getDuties(year) {
    return dispatch => {
        dispatch(request());

        return fireworkService.getDuties(year)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: fireworkConstants.GET_DUTIES_REQUEST } }
    function success(result) { return { type: fireworkConstants.GET_DUTIES_SUCCESS, result } }
    function failure(errorMessages) { return { type: fireworkConstants.GET_DUTIES_FAILURE, errorMessages } }
}

function saveDuties(duties) {
    return dispatch => {
        dispatch(request());

        return fireworkService.saveDuties(duties)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        dispatch(failure(result.errorMessages))
                        return result.errorMessages;
                    } else {
                        dispatch(success(result.result))
                        return result.result;
                    }
                },
                error => {
                    dispatch(failure("Ein technischer Fehler ist aufgetreten"));
                }
            );
    };

    function request() { return { type: fireworkConstants.SAVE_DUTIES_REQUEST } }
    function success(result) { return { type: fireworkConstants.SAVE_DUTIES_SUCCESS, result } }
    function failure(errorMessages) { return { type: fireworkConstants.SAVE_DUTIES_FAILURE, errorMessages } }
}

function toggleEditFireworkModal() {
    return { type: fireworkConstants.TOGGLE_EDIT_FIREWORK_MODEL };
}

function toggleVVKModal() {
    return { type: fireworkConstants.TOGGLE_VVK_MODAL };
}

function toggleImportModal() {
    return { type: fireworkConstants.TOGGLE_IMPORT_MODAL };
}

function toggleAnnualReportsModal() {
    return { type: fireworkConstants.TOGGLE_ANNUAL_REPORTS_MODAL };
}

function toggleManualTicketsModal(firework) {
    return { type: fireworkConstants.TOGGLE_MANUAL_TICKETS_MODAL, firework: firework };
}

function toggleStocktakingModal(firework) {
    return { type: fireworkConstants.TOGGLE_STOCKTAKING_MODAL, firework: firework };
}

function toggleSalesStatistics(firework) {
    return { type: fireworkConstants.TOGGLE_SALES_STATISTICS_MODAL, firework: firework };
}
function toggleDutiesModal() {
    return { type: fireworkConstants.TOGGLE_DUTIES_MODAL };
}

function setDummyProps(props) {
    return { type: fireworkConstants.SET_DUMMY_PROPS, props };
}