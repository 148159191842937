import { budgetConstants, alertConstants } from "../_constants";

export function budgetdata(state={items:[]}, action) {

  switch (action.type) {
    case budgetConstants.OPEN_MODAL_BOOK_INVOICES:
      return {
        ...state,
        bookInvoiceModal: !state.bookInvoiceModal,
        errorMessages: null
      };
    case budgetConstants.OPEN_MODAL_ADJUST_FORECAST:
      return {
        ...state,
        errorMessages: null,
        error: null,
        adjustForecast: !state.adjustForecast
      };
    case budgetConstants.OPEN_MODAL_NEW_FORECAST:
      return {
        ...state,
        errorMessages: null,
        error: null,
        newForecastModal: !state.newForecastModal
      };
    case budgetConstants.OPEN_MODAL_ACCOUNTING_CONFIRMATION:
      return {
        ...state,
        accountingConfirmationModal: !state.accountingConfirmationModal,
        forecastExternalUUID: action.forecastExternalUUID
      };

    case budgetConstants.OPEN_DELETE_FORECAST_MODAL:
      return {
        ...state,
        deleteForecastExternalUUID: action.forecastExternalUUID,
        deleteForecastModal: !state.deleteForecastModal
      };

    case budgetConstants.OPEN_MODAL_NEW_CREDITOR:
      return {
        ...state,
        newCreditorModal: !state.newCreditorModal,
        errorMessages: null,
        creditorSaveSuccessful: false
      };

    case budgetConstants.GET_BUDGET_REQUEST:
      return {
        ...state,
        loading: true,
        resultMessage: null,
        errorMessages: null,
        successMessage: ''
      };
    case budgetConstants.GET_BUDGET_SUCCESS:
      return {
        ...state,
        loading: false,
        budget: action.budget.result
      };
    case budgetConstants.GET_BUDGET_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case budgetConstants.ADJUST_BUDGET_REQUEST:
      return {
        ...state,
        loading: true,
        resultMessage: null,
        errorMessages: null
      };
    case budgetConstants.ADJUST_BUDGET_SUCCESS:
      return {
        ...state,
        loading: false,
        resultMessage: 'Speichern erfolgreich'
      };
    case budgetConstants.ADJUST_BUDGET_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        resultMessage: null
      };

    case budgetConstants.SAVE_BUDGET_BWA_REQUEST:
      return {
        ...state,
        loading: true,
        resultMessage: undefined,
        errorMessages: undefined
      }

    case budgetConstants.SAVE_BUDGET_BWA_SUCCESS:

      return {
        ...state,
        loading: true,
        resultMessage: 'Speichern erfolgreich',
        errorMessages: undefined,
        budget: action.result
      }

    case budgetConstants.SAVE_BUDGET_BWA_FAILURE:
      return {
        ...state,
        errorMessages: action.error,
        loading: false,
        resultMessage: null
      };

    case budgetConstants.GET_FORECAST_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessages: null
      };
    case budgetConstants.GET_FORECAST_SUCCESS:
      return {
        ...state,
        loading: false,
        forecastItems: action.forecast.result
      };
    case budgetConstants.GET_FORECAST_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case budgetConstants.SWITCH_TO_FORECAST:
      return {
        ...state,
        error: action.error,
        loading: false,
        currentForecastUUID: action.forecastExternalUUID,
        currentProjectTitle: action.currentProjectTitle,
        currentProjectNumber: action.currentProjectNumber
      };

    case budgetConstants.SWITCH_TO_PROJECT_DETAILS:
      return {
        ...state,
        error: action.error,
        loading: false,
        projectItemExternalUUID: action.projectItemExternalUUID,
      };


    case budgetConstants.ADD_FORECAST_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessages: null
      };
    case budgetConstants.ADD_FORECAST_SUCCESS:
      return {
        ...state,
        loading: false,
        newForecastModal: false
      };
    case budgetConstants.ADD_FORECAST_FAILURE:
      return {
        ...state,
        errorMessages: action.error,
        loading: false
      };

    case budgetConstants.DELETE_FORECAST_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessages: null
      };
    case budgetConstants.DELETE_FORECAST_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteForecastModal: false
      };
    case budgetConstants.DELETE_FORECAST_FAILURE:
      return {
        ...state,
        errorMessages: action.error,
        loading: false
      };

    case budgetConstants.GET_PROJECT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessages: null,
        currentProject: null
      };
    case budgetConstants.GET_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentProject: action.project.result
      };
    case budgetConstants.GET_PROJECT_DETAILS_FAILURE:
      return {
        ...state,
        errorMessages: action.error,
        loading: false
      };

    case budgetConstants.UPLOAD_INVOICES_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessages: null
      };
    case budgetConstants.UPLOAD_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        bookInvoiceModal: false
      };
    case budgetConstants.UPLOAD_INVOICES_FAILURE:
      return {
        ...state,
        errorMessages: action.errorMessages,
        loading: false
      };

    case budgetConstants.REDUCE_FORECAST_REQUEST:
      return {
        ...state,
        updateAmountLoading: true,
        errorMessages: null
      };
    case budgetConstants.REDUCE_FORECAST_SUCCESS:
      return {
        ...state,
        updateAmountLoading: false,
        accountingConfirmationModal: false
      };
    case budgetConstants.REDUCE_FORECAST_FAILURE:
      return {
        ...state,
        errorMessages: action.errorMessages,
        updateAmountLoading: false
      };

    case budgetConstants.LOAD_ALL_CREDITORS_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessages: null
      };
    case budgetConstants.LOAD_ALL_CREDITORS_SUCCESS:
      return {
        ...state,
        loading: false,
        creditordata: action.result.result
      };
    case budgetConstants.LOAD_ALL_CREDITORS_FAILURE:
      return {
        ...state,
        errorMessages: action.errorMessages,
        loading: false
      };

    case budgetConstants.SAVE_NEW_CREDITOR_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessages: null,
        creditorSaveSuccessful: false,
      };
    case budgetConstants.SAVE_NEW_CREDITOR_SUCCESS:
      return {
        ...state,
        loading: false,
        creditorSaveSuccessful: true,
        newCreditorModal: false,
        creditordata: action.result.result,
      };
    case budgetConstants.SAVE_NEW_CREDITOR_FAILURE:
      return {
        ...state,
        errorMessages: action.errorMessages,
        loading: false,
      };

    case budgetConstants.UPDATE_CREDITOR_REQUEST:
      return {
        ...state,
        subloading: true
      };

    case budgetConstants.UPDATE_CREDITOR_SUCCESS:
      return {
        ...state,
        subloading: false,
        creditordata: action.result.result,
        editCreditorModal: false

      };

    case budgetConstants.UPDATE_CREDITOR_FAILURE:
      return {
        ...state,
        subloading: false,
        errorMessages: action.errorMessages,
      };

    case budgetConstants.DELETE_CREDITOR_REQUEST:
      return {
        ...state,
        subloading: true
      };

    case budgetConstants.DELETE_CREDITOR_SUCCESS:
      return {
        ...state,
        subloading: false,
        creditordata: action.result.result,
        creditorDeletionModal: false,
        creditorToDelete: null
      };

    case budgetConstants.DELETE_CREDITOR_FAILURE:
      return {
        ...state,
        subloading: false,
        errorMessages: action.errorMessages,
      };

    case budgetConstants.TOGGLE_DELETE_CREDITOR_MODAL:
      return {
        ...state,
        creditorDeletionModal: !state.creditorDeletionModal,
        creditorToDelete: action.creditor
      };

    case budgetConstants.TOGGLE_EDIT_CREDITOR_MODAL:
      return {
        ...state,
        editCreditorModal: !state.editCreditorModal
      };

    case budgetConstants.GET_BOOKED_INVOICES_REQUEST:
      return {
        ...state,
        loadingBookedInvoices: true,
        errorMessages: null,
        bookedInvoices: null
      };
    case budgetConstants.GET_BOOKED_INVOICES_SUCCESS:
      return {
        ...state,
        loadingBookedInvoices: false,
        bookedInvoices: action.invoices.result
      };
    case budgetConstants.GET_BOOKED_INVOICES_FAILURE:
      return {
        ...state,
        errorMessages: action.errorMessages,
        loadingBookedInvoices: false,
      };

    case budgetConstants.TOGGLE_BOOKED_INVOICE_MODAL:
      return {
        ...state,
        errorMessages: null,
        error: null,
        bookedInvoiceModal: !state.bookedInvoiceModal
      };

    case budgetConstants.TOGGLE_CREATE_NEXT_YEAR_MODAL:
      return {
        ...state,
        errorMessages: null,
        error: null,
        createNextYearModal: !state.createNextYearModal
      };

    case budgetConstants.TOGGLE_IST_MODAL:
      return {
        ...state,
        errorMessages: null,
        error: null,
        ISTModal: !state.ISTModal
      };

    case budgetConstants.CREATE_NEXT_YEAR_REQUEST:
      return {
        ...state,
        createNextYearModal: true,
        errorMessages: null,
      };
    case budgetConstants.CREATE_NEXT_YEAR_SUCCESS:
      return {
        ...state,
        createNextYearModal: false,
        successMessage: 'Es wurde erfolgreich ein neues Jahr angelegt'
      };
    case budgetConstants.CREATE_NEXT_YEAR_FAILURE:
      return {
        ...state,
        errorMessages: action.errorMessages,
        createNextYearModal: true
      };

    case budgetConstants.SAVE_IST_CHANGE_REQUEST:
      return {
        ...state,
        ISTModal: true,
        errorMessages: null,
      };
    case budgetConstants.SAVE_IST_CHANGE_SUCCESS:
      return {
        ...state,
        ISTModal: false,
      };
    case budgetConstants.SAVE_IST_CHANGE_FAILURE:
      return {
        ...state,
        errorMessages: action.errorMessages,
        ISTModal: true
      };

    case budgetConstants.START_BUDGET_EXCEL_EXPORT_REQUEST:
      return {
        ...state,
        loadingExcelExport: true,
        excelDownloadLink: null,
        errorMessages: null,
      };
    case budgetConstants.START_BUDGET_EXCEL_EXPORT_SUCCESS:
      return {
        ...state,
        loadingExcelExport: false,
        excelDownloadLink: action.result.downloadId
      };
    case budgetConstants.START_BUDGET_EXCEL_EXPORT_FAILURE:
      return {
        ...state,
        errorMessages: action.errorMessages,
        excelDownloadLink: null,
        loadingExcelExport: false
      };

    case budgetConstants.RESET_EXCEL_LINK:
      return {
        ...state,
        errorMessages: null,
        excelDownloadLink: null,
        loadingExcelExport: false,
        multipleDetailsExcelDownloadLink: null,
        loadingMultipleDetailsExcelExport: false
      };

    case budgetConstants.TOGGLE_EDIT_INVOICE_MODAL:
      return {
        ...state,
        editInvoiceModal: !state.editInvoiceModal
      };

    case budgetConstants.UPDATE_INVOICE_REQUEST:
      return {
        ...state,
        subloading: true,
        errorMessages: null
      };

    case budgetConstants.UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        subloading: false,
        errorMessages: null,
        editInvoiceModal: false
      };

    case budgetConstants.UPDATE_INVOICE_FAILURE:
      return {
        ...state,
        subloading: false,
        errorMessages: action.errorMessages
      };

    case budgetConstants.TOGGLE_DELETION_MODAL: {
      return {
        ...state,
        deletionModal: !state.deletionModal,
        invoiceToDelete: action.invoiceId
      }
    }

    case budgetConstants.DELETE_INVOICE_REQUEST:
      return {
        ...state,
        deletionLoading: true,
      };

    case budgetConstants.DELETE_INVOICE_SUCCESS:
      return {
        ...state,
        deletionLoading: false,
        deletionModal: false,
        invoiceToDelete: null
      };

    case budgetConstants.DELETE_INVOICE_FAILURE:
      return {
        ...state,
        deletionLoading: true,
        deletionErrorMessages: action.errorMessages
      };

      /* CONSTANT DOESNT EXIST
      case budgetConstants.TITLE_CHANGE:
      return {
        ...state,
        deletionLoading: true,
        deletionErrorMessages: action.errorMessages
      };
       */

    case budgetConstants.SELECT_YEAR:
      return {
        ...state,
        selectedYear: action.year
      };

    case alertConstants.CLEAR:
      return {
        ...state,
        errorMessages: null,
        creditorSaveSuccessful: false,
        loading: false,
      };

    case budgetConstants.TOGGLE_IST_CONFIRMATION_MODAL:
      return {
        ...state,
        istConfirmationModal: !state.istConfirmationModal,
        istConfirmationUUID: action.forecastExternalUUID,
        errorMessages: null
      }

    case budgetConstants.START_IST_CONFIRMATION_REQUEST:
      return {
        ...state,
        istConfirmationLoading: true,
        errorMessages: null
      }

    case budgetConstants.START_IST_CONFIRMATION_SUCCESS:
      console.log("START_IST_CONFIRMATION_SUCCESS");
      console.log(action);
      let forecast = action.forecast;
      let forecasts = state.forecastItems;
      let index = forecasts.findIndex((item) => {
        return forecast.externalUUID === item.externalUUID;
      });
      console.log(forecast);
      console.log(forecasts);
      console.log("index");
      console.log(index);
      if (index > -1) {
        forecasts.splice(index, 1);
      }

      return {
        ...state,
        istConfirmationLoading: false,
        istConfirmationModal: false,
        istConfirmationUUID: null,
        forecastItems: forecasts
      }

    case budgetConstants.START_IST_CONFIRMATION_FAILURE:
      return {
        ...state,
        istConfirmationLoading: false,
        errorMessages: action.error
      }

    default:
      return state;
  }
}