import { addressConstants } from '../_constants';
import { addressService } from '../_services';
import { alertActions } from "./alert.actions";

export const addressActions = {
    getAddresses,
    updateAddress,
    uploadAddressFile,
    addAddressData,
    getSearchData,
    searchForAddresses,
    toggleImportModal,
    handleEdit,
};

function getAddresses(searchdata) {
    return dispatch => {
        dispatch(request());

        return addressService.getAddresses(searchdata)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: addressConstants.GET_ADDRESSES_REQUEST } }
    function success(result) { return { type: addressConstants.GET_ADDRESSES_SUCCESS, result  } }
    function failure(errorMessages) { return { type: addressConstants.GET_ADDRESSES_FAILURE, errorMessages } }
}

function addAddressData(data) {
    return dispatch => {
        dispatch(request());

        return addressService.addAddressData(data)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: addressConstants.ADD_ADDRESS_DATA_REQUEST } }
    function success(result) { return { type: addressConstants.ADD_ADDRESS_DATA_SUCCESS, result  } }
    function failure(errorMessages) { return { type: addressConstants.ADD_ADDRESS_DATA_FAILURE, errorMessages } }
}

function updateAddress(address) {
    return dispatch => {
        dispatch(request());

        return addressService.updateAddress(address)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: addressConstants.UPDATE_ADDRESS_REQUEST } }
    function success(result) { return { type: addressConstants.UPDATE_ADDRESS_SUCCESS, result  } }
    function failure(errorMessages) { return { type: addressConstants.UPDATE_ADDRESS_FAILURE, errorMessages } }
}

function uploadAddressFile(file) {
    return dispatch => {
        dispatch(request());

        return addressService.uploadAddressFile(file)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 1) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
        );
    };

    function request() {
        return {type: addressConstants.UPLOAD_ADDRESS_FILE_REQUEST}
    }

    function success(result) {
        return {type: addressConstants.UPLOAD_ADDRESS_FILE_SUCCESS, result}
    }

    function failure(errorMessages) {
        return {type: addressConstants.UPLOAD_ADDRESS_FILE_FAILURE, errorMessages}
    }
}

function getSearchData() {
    return dispatch => {
        dispatch(request());

        return addressService.getSearchData()
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: addressConstants.GET_SEARCH_INFO_DATA_REQUEST } }
    function success(result) { return { type: addressConstants.GET_SEARCH_INFO_DATA_SUCCESS, result  } }
    function failure(errorMessages) { return { type: addressConstants.GET_SEARCH_INFO_DATA_FAILURE, errorMessages } }
}

function searchForAddresses(searchData) {
    return dispatch => {
        dispatch(request());

        return addressService.searchForAddresses(searchData)
            .then(
                result => {
                    if (result.errorMessages && result.errorMessages.length > 0) {
                        return dispatch(failure(result.errorMessages))
                    } else {
                        return dispatch(success(result.result))
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: addressConstants.SEARCH_REQUEST } }
    function success(result) { return { type: addressConstants.SEARCH_REQUEST_SUCCESS, result  } }
    function failure(errorMessages) { return { type: addressConstants.SEARCH_REQUEST_FAILURE, errorMessages } }
}

function toggleImportModal() {
    return dispatch => {
        dispatch({ type: addressConstants.TOGGLE_MODAL_IMPORT });
    };
}

function handleEdit(index) {
    return dispatch => {
        dispatch({ type: addressConstants.HANDLE_EDIT, index });
    };
}