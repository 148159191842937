import { citytoursConstants, alertConstants } from "../_constants";

export function citytours(state={items:[], importModal: false}, action) {

  switch (action.type) {

    case alertConstants.CLEAR:
      return {
        ...state,
        errorMessages: [],
        loading: false,
        newCityTourModal : false
      };

      let tours;
      let index;
      let visitors;
      let guides;
      let meetingPoints;

    case citytoursConstants.SET_SEARCH_DATA:
      return {
        ...state,
        searchData: action.searchData
      };

    case citytoursConstants.TOGGLE_NEW_CITY_TOUR_MODAL:
      return {
        ...state,
        newCityTourModal : !state.newCityTourModal,
        errorMessages: []
      };

    case citytoursConstants.TOGGLE_DELETE_CITY_TOUR_MODAL:
      return {
        ...state,
        deleteCityTourId: action.externalUUID,
        deleteCityTourModal : !state.deleteCityTourModal,
        errorMessages: []
      };

    case citytoursConstants.TOGGLE_DELETE_BOOKED_VISITOR_MODAL:
      return{
        ...state,
        deleteBookedVisitorId: action.externalUUID,
        deleteBookedVisitorModal : !state.deleteBookedVisitorModal,
        errorMessages: []
      };


    case citytoursConstants.TOGGLE_AVAILABLE_CITY_TOUR_MODAL:
      return {
        ...state,
        availableCityToursModal : !state.availableCityToursModal,
        errorMessages: [],
      };

    case citytoursConstants.TOGGLE_EDIT_CITY_TOUR_MODAL:
      return {
        ...state,
        editCityTourModal : !state.editCityTourModal,
        errorMessages: []
      };

    case citytoursConstants.TOGGLE_VISITORS_MODAL:
      return{
        ...state,
        editVisitorsModal : !state.editVisitorsModal,
        errorMessages: []
      };

    case citytoursConstants.TOGGLE_TOUR_GUIDE_MODAL:
      return {
        ...state,
        detailedTourGuide: action.detailedTourGuide,
        tourGuideModal : !state.tourGuideModal,
        errorMessages: []
      };

    case citytoursConstants.TOGGLE_EDIT_TOUR_GUIDE_MODAL:
      return {
        ...state,
        editTourGuideModal : !state.editTourGuideModal,
        errorMessages: []
      };

    case citytoursConstants.TOGGLE_DELETE_TOURGUIDE:
      return {
        ...state,
        deleteTourGuideId: action.externalUUID,
        deleteTourGuideModal: !state.deleteTourGuideModal,
      };

    case citytoursConstants.GET_CITYTOUR_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessages: []
      };

    case citytoursConstants.GET_CITYTOUR_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessages: [],
        citytour: action.result
      };

    case citytoursConstants.GET_CITYTOUR_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessages: [action.errorMessages]
      };

    case citytoursConstants.GET_CITYTOURS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case citytoursConstants.GET_CITYTOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessages: [],
        citytours: action.result
      };

    case citytoursConstants.GET_CITYTOURS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessages: [action.errorMessages]
      };

    case citytoursConstants.UPDATE_CITYTOUR_REQUEST:
      return {
        ...state,
        subloading: true
      };

    case citytoursConstants.UPDATE_CITYTOUR_SUCCESS:
      tours = state.citytours;
      index = tours.findIndex((item) => {
        return item.externalUUID === action.result.externalUUID;
      });
      if (index > -1) {
        tours[index] = action.result;
      } else {
        tours = tours.concat(action.result);
      }
      return {
        ...state,
        subloading: false,
        errorMessages: [],
        citytours: tours,
        editCityTourModal: false
      };

    case citytoursConstants.UPDATE_CITYTOUR_FAILURE:
      return {
        ...state,
        subloading: false,
        errorMessages: [[action.errorMessages]]
      };

    case citytoursConstants.DELETE_CITYTOUR_REQUEST:
      return {
        ...state,
        subloading: true,
      };

    case citytoursConstants.DELETE_CITYTOUR_SUCCESS:
      tours = state.citytours;
      index = tours.findIndex((item) => {
        return item.externalUUID === action.result.externalUUID;
      });
      tours.splice(index, 1);
      return {
        ...state,
        citytours: tours,
        subloading: false,
        errorMessages: [],
        deleteCityTourModal: false

      };

    case citytoursConstants.DELETE_CITYTOUR_FAILURE:
      return {
        ...state,
        subloading: false,
        errorMessages: [action.errorMessages]
      };

    case citytoursConstants.GET_AVAILABLE_TOURS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case citytoursConstants.GET_AVAILABLE_TOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessages: [],
        availableCityTours: action.result
      };

    case citytoursConstants.GET_AVAILABLE_TOURS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessages: [action.errorMessages]
      };

    case citytoursConstants.UPDATE_AVAILABLE_TOUR_REQUEST:
      return {
        ...state,
        subloading: true
      };

    case citytoursConstants.UPDATE_AVAILABLE_TOUR_SUCCESS:
      tours = state.availableCityTours;
      index = tours.findIndex((item) => {
        return item.externalUUID === action.result.externalUUID;
      });
      if (index > -1) {
        tours[index] = action.result;
      } else {
        tours = tours.concat(action.result);
      }
      return {
        ...state,
        subloading: false,
        errorMessages: [],
        availableCityTours: tours,
        availableCityToursModal: false
      };

    case citytoursConstants.UPDATE_AVAILABLE_TOUR_FAILURE:
      return {
        ...state,
        subloading: false,
        errorMessages: [action.errorMessages]
      };

    case citytoursConstants.DELETE_AVAILABLE_TOUR_REQUEST:
      return {
        ...state,
        subloading: true,
      };

    case citytoursConstants.DELETE_AVAILABLE_TOUR_SUCCESS:
      tours = state.availableCityTours;
      index = tours.findIndex((item) => {
        return item.externalUUID === action.result.externalUUID;
      });
      tours.splice(index, 1);
      return {
        ...state,
        availableCityTours: tours,
        subloading: false,
        errorMessages: [],
        availableCityToursModal: false
      };

    case citytoursConstants.DELETE_AVAILABLE_TOUR_FAILURE:
      return {
        ...state,
        subloading: false,
        errorMessages: [action.errorMessages]
      };

    case citytoursConstants.GET_TOURGUIDE_REQUEST:
      return {
        ...state,
        loading: true
      };

    case citytoursConstants.GET_TOURGUIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessages: [],
        guides: action.result
      };

    case citytoursConstants.GET_TOURGUIDE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessages: [action.errorMessages]
      };

    case citytoursConstants.UPDATE_TOURGUIDE_REQUEST:
      return {
        ...state,
        subloading: true,
      };

    case citytoursConstants.UPDATE_TOURGUIDE_SUCCESS:
      guides = state.guides;
      index = guides.findIndex((item) => {
        return item.externalUUID === action.result.externalUUID;
      });
      if (index === -1) {
        guides.push(action.result);
      } else {
        guides[index] = action.result;
      }
      return {
        ...state,
        guides: guides,
        subloading: false,
        errorMessages: [],
        editTourGuideModal: false
      };

    case citytoursConstants.UPDATE_TOURGUIDE_FAILURE:
      return {
        ...state,
        subloading: false,
        errorMessages: [action.errorMessages]
      };

    case citytoursConstants.DELETE_TOURGUIDE_REQUEST:
      return {
        ...state,
        subloading: true,
      };

    case citytoursConstants.DELETE_TOURGUIDE_SUCCESS:
      guides = state.guides;
      index = guides.findIndex((item) => {
        return item.externalUUID === state.deleteTourGuideId;
      });
      guides.splice(index, 1);
      return {
        ...state,
        guides: guides,
        subloading: false,
        errorMessages: []
      };

    case citytoursConstants.DELETE_TOURGUIDE_FAILURE:
      return {
        ...state,
        subloading: false,
        errorMessages: [action.errorMessages]
      };

    case citytoursConstants.GET_BOOKEDVISITOR_REQUEST:
      return {
        ...state,
        loading: true
      };

    case citytoursConstants.GET_BOOKEDVISITOR_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessages: [],
        visitors: action.result
      };

    case citytoursConstants.GET_BOOKEDVISITOR_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessages: [action.errorMessages]
      };

    case citytoursConstants.UPDATE_BOOKEDVISITOR_REQUEST:
      return {
        ...state,
        subloading: true
      };

    case citytoursConstants.UPDATE_BOOKEDVISITOR_SUCCESS:
      visitors = state.visitors;
      if (!visitors) {
        visitors = [action.result]
      } else {
        index = visitors.findIndex((item) => {
          return item.externalUUID === action.result.externalUUID;
        });
        if (index > -1) {
          visitors[index] = action.result;
        } else {
          visitors = visitors.concat(action.result);
        }
      }
      return {
        ...state,
        subloading: false,
        errorMessages: [],
        visitors: visitors,
        editVisitorsModal: false
      };

    case citytoursConstants.UPDATE_BOOKEDVISITOR_FAILURE:
      return {
        ...state,
        subloading: false,
        errorMessages: [action.errorMessages]
      };

    case citytoursConstants.DELETE_BOOKEDVISITOR_REQUEST:
      return {
        ...state,
        subloading: true,
      };

    case citytoursConstants.DELETE_BOOKEDVISITOR_SUCCESS:
      visitors = state.visitors;
      index = visitors.findIndex((item) => {
        return item.externalUUID === action.result.externalUUID;
      });
      visitors.splice(index, 1);
      return {
        ...state,
        visitors: visitors,
        subloading: false,
        errorMessages: [],
        deleteBookedVisitorModal: false
      };

    case citytoursConstants.DELETE_BOOKEDVISITOR_FAILURE:
      return {
        ...state,
        subloading: false,
        errorMessages: [action.errorMessages]
      };

    case citytoursConstants.PREPARE_EXCEL_EXPORT_REQUEST:
      return {
        ...state,
        prepareExportLoading: true,
        exportModal: true,
        excelExportLink: ""
      };

    case citytoursConstants.PREPARE_EXCEL_EXPORT_SUCCESS:
      return {
        ...state,
        prepareExportLoading: false,
        downloadId: action.downloadId
      };

    case citytoursConstants.PREPARE_EXCEL_EXPORT_FAILURE:
      return {
        ...state,
        prepareExportLoading: false,
        errorMessages: [action.errorMessages]
      };

    case citytoursConstants.TOGGLE_EXPORT_MODAL:
      return {
        ...state,
        exportModal: !state.exportModal
      };

    case citytoursConstants.TOGGLE_IMPORT_MODAL:
      return {
        ...state,
        importModal: !state.importModal,
        errorMessages: [],
        successMessage: null,
        submitted: false
      };

    case citytoursConstants.IMPORT_CITY_TOURS_REQUEST:
      return {
        ...state,
        loading: true,
        submitted: true,
        imported: false,
        errorMessages: [],
        successMessage: null,
      };

    case citytoursConstants.IMPORT_CITY_TOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        submitted: false,
        imported: true,
        successMessage: action.successMessage,
        errorMessages: null
      };

    case citytoursConstants.IMPORT_CITY_TOURS_FAILURE:
      return {
        ...state,
        loading: false,
        submitted: false,
        imported: false,
        errorMessages: [action.errorMessages],
        successMessage: null
      };



      // MEETING POINT
    case citytoursConstants.GET_MEETING_POINTS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case citytoursConstants.GET_MEETING_POINTS_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessages: [],
        meetingPoints: action.result
      };

    case citytoursConstants.GET_MEETING_POINTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessages: [action.errorMessages]
      };

    case citytoursConstants.TOGGLE_MEETING_POINT_MODAL:
      return {
        ...state,
        meetingPointModal : !state.meetingPointModal,
        errorMessages: []
      };

    case citytoursConstants.TOGGLE_EDIT_MEETING_POINT_MODAL:
      return {
        ...state,
        editMeetingPointModal : !state.editMeetingPointModal,
        errorMessages: []
      };

    case citytoursConstants.UPDATE_MEETING_POINT_REQUEST:
      return {
        ...state,
        subloading: true,
      };

    case citytoursConstants.UPDATE_MEETING_POINT_SUCCESS:
      meetingPoints = state.meetingPoints;
      index = meetingPoints.findIndex((item) => {
        return item.externalUUID === action.result.externalUUID;
      });
      console.log("***");
      console.log(meetingPoints);
      console.log("***");
      if (index === -1) {
        console.log("MEETING POINT NOT FOUND")
        meetingPoints.push(action.result);
        meetingPoints.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          } else {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }
        });
      } else {
        meetingPoints[index] = action.result;
      }
      return {
        ...state,
        meetingPoints: meetingPoints,
        subloading: false,
        errorMessages: [],
        editMeetingPointModal: false
      };

    case citytoursConstants.UPDATE_MEETING_POINT_FAILURE:
      return {
        ...state,
        subloading: false,
        errorMessages: [action.errorMessages]
      };

    case citytoursConstants.TOGGLE_DELETE_MEETING_POINT_MODAL:
      return {
        ...state,
        deleteMeetingPointId: action.externalUUID,
        deleteMeetingPointModal: !state.deleteMeetingPointModal,
        errorMessages: []
      };

    case citytoursConstants.DELETE_MEETING_POINT_REQUEST:
      return {
        ...state,
        subloading: true,
      };

    case citytoursConstants.DELETE_MEETING_POINT_SUCCESS:
      meetingPoints = state.meetingPoints;
      index = meetingPoints.findIndex((item) => {
        return item.externalUUID === state.deleteMeetingPointId;
      });
      if (index > -1) {
        meetingPoints.splice(index, 1);
      } else {
        console.log("MEETING POINT NOT FOUND");
      }
      return {
        ...state,
        meetingPoints: meetingPoints,
        subloading: false,
        errorMessages: [],
        deleteMeetingPointId: null,
        deleteMeetingPointModal: false,
      };

    case citytoursConstants.DELETE_MEETING_POINT_FAILURE:
      return {
        ...state,
        subloading: false,
        errorMessages: [action.errorMessages],
        deleteMeetingPointId: null,
        deleteMeetingPointModal: false,
      };

    default:
      return state;
  }
}