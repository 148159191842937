import { organizationConstants } from '../_constants';


export function organization(state = {}, action) {
  switch (action.type) {
    case organizationConstants.GETORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case organizationConstants.GETORGANIZATION_SUCCESS:
      return {
        name: action.organization.name,
        users: action.organization.users
      };
    case organizationConstants.GETORGANIZATION_FAILURE:
      return {
        error: action.error
      };
    case organizationConstants.TOGGLE_ADD_CLIENT_MODAL:
      return {
        ...state,
        addClientModal: !state.addClientModal
      };
    case organizationConstants.ADD_CLIENT_REQUEST:
      return {
        loading: true
      };
    case organizationConstants.ADD_CLIENT_SUCCESS:
      return {
        ...state,
        name: action.organization.name,
        singleOrganization: action.organization.singleOrganization,
        users: action.organization.users,
        datevUpstreamSettings: action.organization.datevUpstreamSettings,
        addClientModal: false
      };
    case organizationConstants.ADD_CLIENT_FAILURE:
      return {
        error: action.error
      };
    case organizationConstants.SET_IMPORT_ALL_CLIENTS:
      return {
        ...state,
        datevUpstreamSettings:{
          ...state.datevUpstreamSettings,
          importAllClients: action.importAllClients
        }
      };
    default:
      return state
  }
}