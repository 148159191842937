import { navigationConstants } from "../_constants";

export function navigation(state={items:[]}, action) {

    if(action.type===navigationConstants.UPDATE_NAVIGATION_ITEMS && action.allowedApplications){

      const items = [];
    
      items.push( {
        title: true,
        name: 'HMTG',
        wrapper: {            // optional wrapper object
          element: '',        // required valid HTML5 element tag
          attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
        },
        class: ''             // optional class names space delimited list for title item ex: "text-center"
      });

      var allowedModules = [];
      action.allowedApplications.forEach(a=> allowedModules.push(a.moduleType));

      if(allowedModules.includes('BUDGET')){
        items.push( {
          name: 'Budget Übersicht',
          url: '/budget',
          icon: 'fa fa-eur',
        });
      }
      
      if(allowedModules.includes('ADDRESSES')){
        items.push( {
          name: 'Adressen',
          url: '/adressen',
          icon: 'fa fa-address-book',
        });
      }

      if(allowedModules.includes('EVENT')){
        items.push( {
          name: 'Veranstaltungen',
          url: '/event',
          icon: 'fas fa-calendar-alt',
        });
      }

      if(allowedModules.includes('FIREWORK')){
        items.push( {
          name: 'Feuerwerk',
          url: '/firework',
          icon: 'fas fa-rocket',
        });
      }

      if(allowedModules.includes('GIFT_CARD')){
        items.push( {
          name: 'Gutscheinbuch',
          url: '/giftcards',
          icon: 'fa fa-gift',
        });
      }

      if(allowedModules.includes('CITY_TOUR')){
        items.push( {
          name: 'Stadtführungen',
          url: '/stadtfuehrungen',
          icon: 'fa fa-calendar-o',
        });
      }
      
      return {items : items};
    }
    
    return state;
  }