export const citytoursConstants = {
    
    TOGGLE_NEW_CITY_TOUR_MODAL : 'TOGGLE_NEW_CITY_TOUR_MODAL',
    TOGGLE_DELETE_CITY_TOUR_MODAL : 'TOGGLE_DELETE_CITY_TOUR_MODAL',
    TOGGLE_AVAILABLE_CITY_TOUR_MODAL : 'TOGGLE_AVAILABLE_CITY_TOUR_MODAL',
    TOGGLE_EDIT_CITY_TOUR_MODAL : 'TOGGLE_EDIT_CITY_TOUR_MODAL',
    TOGGLE_VISITORS_MODAL : 'TOGGLE_VISITORS_MODAL',
    TOGGLE_ADD_PARTICIPANTS_MODAL : 'TOGGLE_ADD_PARTICIPANTS_MODAL',
    TOGGLE_TOUR_GUIDE_MODAL : 'TOGGLE_TOUR_GUIDE_MODAL',
    TOGGLE_NEW_TOUR_GUIDE_MODAL : 'TOGGLE_NEW_TOUR_GUIDE_MODAL',
    TOGGLE_EDIT_TOUR_GUIDE_MODAL : 'TOGGLE_EDIT_TOUR_GUIDE_MODAL',

    TOGGLE_DELETE_TOURGUIDE : 'TOGGLE_DELETE_TOURGUIDE',

    TOGGLE_DELETE_BOOKED_VISITOR_MODAL : 'TOGGLE_DELETE_BOOKED_VISITOR_MODAL',

    SET_SEARCH_DATA : 'SET_SEARCH_DATA',

    GET_CITYTOUR_REQUEST : 'GET_CITYTOUR_REQUEST',
    GET_CITYTOUR_SUCCESS : 'GET_CITYTOUR_SUCCESS',
    GET_CITYTOUR_FAILURE : 'GET_CITYTOUR_FAILURE',

    GET_CITYTOURS_REQUEST : 'GET_CITYTOURS_REQUEST',
    GET_CITYTOURS_SUCCESS : 'GET_CITYTOURS_SUCCESS',
    GET_CITYTOURS_FAILURE : 'GET_CITYTOURS_FAILURE',

    UPDATE_CITYTOUR_REQUEST : 'UPDATE_CITYTOUR_REQUEST',
    UPDATE_CITYTOUR_SUCCESS : 'UPDATE_CITYTOUR_SUCCESS',
    UPDATE_CITYTOUR_FAILURE : 'UPDATE_CITYTOUR_FAILURE',

    DELETE_CITYTOUR_REQUEST : 'DELETE_CITYTOUR_REQUEST',
    DELETE_CITYTOUR_SUCCESS : 'DELETE_CITYTOUR_SUCCESS',
    DELETE_CITYTOUR_FAILURE : 'DELETE_CITYTOUR_FAILURE',

    GET_AVAILABLE_TOURS_REQUEST : 'GET_AVAILABLE_TOURS_REQUEST',
    GET_AVAILABLE_TOURS_SUCCESS : 'GET_AVAILABLE_TOURS_SUCCESS',
    GET_AVAILABLE_TOURS_FAILURE : 'GET_AVAILABLE_TOURS_FAILURE',

    UPDATE_AVAILABLE_TOUR_REQUEST : 'UPDATE_AVAILABLE_TOUR_REQUEST',
    UPDATE_AVAILABLE_TOUR_SUCCESS : 'UPDATE_AVAILABLE_TOUR_SUCCESS',
    UPDATE_AVAILABLE_TOUR_FAILURE : 'UPDATE_AVAILABLE_TOUR_FAILURE',

    DELETE_AVAILABLE_TOUR_REQUEST : 'DELETE_AVAILABLE_TOUR_REQUEST',
    DELETE_AVAILABLE_TOUR_SUCCESS : 'DELETE_AVAILABLE_TOUR_SUCCESS',
    DELETE_AVAILABLE_TOUR_FAILURE : 'DELETE_AVAILABLE_TOUR_FAILURE',

    GET_TOURGUIDE_REQUEST : 'GET_TOURGUIDE_REQUEST',
    GET_TOURGUIDE_SUCCESS : 'GET_TOURGUIDE_SUCCESS',
    GET_TOURGUIDE_FAILURE : 'GET_TOURGUIDE_FAILURE',

    UPDATE_TOURGUIDE_REQUEST : 'UPDATE_TOURGUIDE_REQUEST',
    UPDATE_TOURGUIDE_SUCCESS: 'UPDATE_TOURGUIDE_SUCCESS',
    UPDATE_TOURGUIDE_FAILURE: 'UPDATE_TOURGUIDE_FAILURE',

    DELETE_TOURGUIDE_REQUEST: 'DELETE_TOURGUIDE_REQUEST',
    DELETE_TOURGUIDE_SUCCESS : 'DELETE_TOURGUIDE_SUCCESS',
    DELETE_TOURGUIDE_FAILURE : 'DELETE_TOURGUIDE_FAILURE',

    GET_BOOKEDVISITOR_REQUEST : 'GET_BOOKEDVISITOR_REQUEST',
    GET_BOOKEDVISITOR_SUCCESS : 'GET_BOOKEDVISITOR_SUCCESS',
    GET_BOOKEDVISITOR_FAILURE : 'GET_BOOKEDVISITOR_FAILURE',

    UPDATE_BOOKEDVISITOR_REQUEST : 'UPDATE_BOOKEDVISITOR_REQUEST',
    UPDATE_BOOKEDVISITOR_SUCCESS: 'UPDATE_BOOKEDVISITOR_SUCCESS',
    UPDATE_BOOKEDVISITOR_FAILURE : 'UPDATE_BOOKEDVISITOR_FAILURE',

    DELETE_BOOKEDVISITOR_REQUEST : 'DELETE_BOOKEDVISITOR_REQUEST',
    DELETE_BOOKEDVISITOR_SUCCESS : 'DELETE_BOOKEDVISITOR_SUCCESS',
    DELETE_BOOKEDVISITOR_FAILURE: 'DELETE_BOOKEDVISITOR_FAILURE',

    PREPARE_EXCEL_EXPORT_REQUEST : 'PREPARE_EXCEL_EXPORT_REQUEST',
    PREPARE_EXCEL_EXPORT_SUCCESS : 'PREPARE_EXCEL_EXPORT_SUCCESS',
    PREPARE_EXCEL_EXPORT_FAILURE: 'PREPARE_EXCEL_EXPORT_FAILURE',

    TOGGLE_EXPORT_MODAL: 'TOGGLE_EXPORT_MODAL',
    TOGGLE_IMPORT_MODAL: 'TOGGLE_IMPORT_MODAL',

    IMPORT_CITY_TOURS_REQUEST : "IMPORT_CITY_TOURS_REQUEST",
    IMPORT_CITY_TOURS_SUCCESS : "IMPORT_CITY_TOURS_SUCCESS",
    IMPORT_CITY_TOURS_FAILURE : 'IMPORT_CITY_TOURS_FAILURE',

    TOGGLE_MEETING_POINT_MODAL : 'TOGGLE_MEETING_POINT_MODAL',
    TOGGLE_NEW_MEETING_POINT_MODAL : 'TOGGLE_NEW_MEETING_POINT_MODAL',
    TOGGLE_EDIT_MEETING_POINT_MODAL : 'TOGGLE_EDIT_MEETING_POINT_MODAL',
    TOGGLE_DELETE_MEETING_POINT_MODAL : 'TOGGLE_DELETE_MEETING_POINT_MODAL',

    GET_MEETING_POINTS_REQUEST : 'GET_MEETING_POINTS_REQUEST',
    GET_MEETING_POINTS_SUCCESS : 'GET_MEETING_POINTS_SUCCESS',
    GET_MEETING_POINTS_FAILURE : 'GET_MEETING_POINTS_FAILURE',

    UPDATE_MEETING_POINT_REQUEST : 'UPDATE_MEETING_POINT_REQUEST',
    UPDATE_MEETING_POINT_SUCCESS : 'UPDATE_MEETING_POINT_SUCCESS',
    UPDATE_MEETING_POINT_FAILURE : 'UPDATE_MEETING_POINT_FAILURE',

    DELETE_MEETING_POINT_REQUEST : 'DELETE_MEETING_POINT_REQUEST',
    DELETE_MEETING_POINT_SUCCESS : 'DELETE_MEETING_POINT_SUCCESS',
    DELETE_MEETING_POINT_FAILURE : 'DELETE_MEETING_POINT_FAILURE',
};
