import { giftCardConstants } from '../_constants';

export function giftcard(state = {}, action) {
  let giftcards;
  switch (action.type) {
    case giftCardConstants.GET_GIFT_CARDS_REQUEST:
      return {
        ...state,
        loading: !state.giftcards,
        searchLoading: state.giftcards,
        errorMessages: null,
        giftcards: null,
        modalErrorMessages: null,
        redeemModal: false,
        importModal: false,
        addModal: false,
        redemptionModal: false
      }

    case giftCardConstants.GET_GIFT_CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchLoading: false,
        giftcards: action.result
      }

    case giftCardConstants.GET_GIFT_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessages: action.errorMessages
      }

    case giftCardConstants.GET_GIFT_CARD_OVERVIEW_REQUEST:
      return {
        ...state,
        loading: true,
        errorMessages: null,
        overview: null
      }

    case giftCardConstants.GET_GIFT_CARD_OVERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        overview: action.result
      }

    case giftCardConstants.GET_GIFT_CARD_OVERVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessages: action.errorMessages,
      }

    case giftCardConstants.ADD_GIFT_CARD_REQUEST:
      return {
        ...state,
        addLoading: true,
        modalErrorMessages: null
      }

    case giftCardConstants.ADD_GIFT_CARD_SUCCESS:
      giftcards = state.giftcards;
      if (!giftcards) {
        giftcards = [];
      }
      giftcards.push(action.result);
      giftcards.sort((a, b) => (a.createdAt > b.createdAt ? 1 : (a.createdAt < b.createdAt ? -1 : 0)))

      return {
        ...state,
        addLoading: false,
        giftcards: giftcards,
        addModal: false
      }

    case giftCardConstants.ADD_GIFT_CARD_FAILURE:
      return {
        ...state,
        addLoading: false,
        modalErrorMessages: action.errorMessages
      }

    case giftCardConstants.TOGGLE_ADD_MODAL:
      return {
        ...state,
        addModal: !state.addModal,
        modalErrorMessages: null
      }

    case giftCardConstants.TOGGLE_IMPORT_MODAL:
      return {
        ...state,
        importModal: !state.importModal,
        modalErrorMessages: null
      }

    case giftCardConstants.TOGGLE_REDEEM_MODAL:
    return {
      ...state,
      redeemModal: !state.redeemModal,
      selectedGiftcard: action.giftcard,
      modalErrorMessages: null
    }

    case giftCardConstants.TOGGLE_REDEMPTION_MODAL:
      return {
        ...state,
        redemptionModal: !state.redemptionModal,
        selectedGiftcard: action.giftcard,
        modalErrorMessages: null
      }

    case giftCardConstants.REDEEM_GIFT_CARD_REQUEST:
      return {
        ...state,
        redeemLoading: true,
        redeemErrorMessages: null,
      }

    case giftCardConstants.REDEEM_GIFT_CARD_SUCCESS:
      giftcards = state.giftcards;
      let index = giftcards.findIndex((item) => {
        return item.externalUUID === action.result.externalUUID;
      });
      giftcards[index] = action.result
      return {
        ...state,
        redeemLoading: false,
        giftcards: giftcards,
        selectedGiftcard: null,
        redeemModal: false,
      }

    case giftCardConstants.REDEEM_GIFT_CARD_FAILURE:
      return {
        ...state,
        redeemLoading: false,
        redeemErrorMessages: action.errorMessages,
      }

    case giftCardConstants.UPLOAD_GIFT_CARD_IMPORT_FILE_REQUEST:
      return {
        ...state,
        importLoading: true,
        modalErrorMessages: null
      }

    case giftCardConstants.UPLOAD_GIFT_CARD_IMPORT_FILE_SUCCESS:
      return {
        ...state,
        importLoading: false,
        importModal: false,
        overview: null,
        giftcards: null
      }

    case giftCardConstants.UPLOAD_GIFT_CARD_IMPORT_FILE_FAILURE:
      return {
        ...state,
        importLoading: false,
        modalErrorMessages: action.errorMessages
      }

    case giftCardConstants.START_GIFT_CARD_EXCEL_EXPORT_REQUEST:
      return {
        ...state,
        exportLoading: true,
        errorMessages: null,
      }
    case giftCardConstants.START_GIFT_CARD_EXCEL_EXPORT_SUCCESS:
      return {
        ...state,
        exportLoading: false,
        excelDownloadLink: action.result
      }

    case giftCardConstants.START_GIFT_CARD_EXCEL_EXPORT_FAILURE:
      return {
        ...state,
        exportLoading: false,
        errorMessages: action.errorMessages,
      }



    default:
      return state
  }
}