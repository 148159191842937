export function formatDate(date,attachYear){
    var d = new Date(date);
    if(date===null){
      return "";
    }
    var datestring = ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2);
    if(attachYear){
      datestring+= "." + d.getFullYear();
    }
    if(datestring==='01.01.1970' || datestring==='0000-00-00'){
      datestring = '';
    }
    return datestring;
  }