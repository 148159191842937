import { authHeader } from '../_helpers';
import "isomorphic-fetch";
import { handleResponse } from './responseHandler';

export const eventService = {
    getEventOverview,
    getEventCategories,
    getAvailableEventYears,
    updateEventCategories,
    createNewEvent,
    saveEarningOrExpense,
    deleteEarningOrExpense,
    setPLANValue
};


function getEventOverview(year) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/event/overview/' + year, requestOptions).then(handleResponse);
}

function getEventCategories() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/event/categories', requestOptions).then(handleResponse);
}

function updateEventCategories(categories) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(categories)
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/event/categories', requestOptions).then(handleResponse);
}

function getAvailableEventYears() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/event/years', requestOptions).then(handleResponse);
}

function createNewEvent(name, year) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({name: name, year: year})
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/event', requestOptions).then(handleResponse);
}

function saveEarningOrExpense(requestDto) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(requestDto)
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/event/tracking', requestOptions).then(handleResponse);
}

function deleteEarningOrExpense(objectUUID) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/event/tracking/' + objectUUID, requestOptions).then(handleResponse);
}

function setPLANValue(requestDto) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(requestDto)
    };
    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/event/plan', requestOptions).then(handleResponse);
}