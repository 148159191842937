export const eventConstants = {
    GET_EVENTS_OVERVIEW_REQUEST : 'GET_EVENTS_OVERVIEW_REQUEST',
    GET_EVENTS_OVERVIEW_SUCCESS : 'GET_EVENTS_OVERVIEW_SUCCESS',
    GET_EVENTS_OVERVIEW_FAILURE : 'GET_EVENTS_OVERVIEW_FAILURE',

    CREATE_NEW_EVENT_REQUEST : 'CREATE_NEW_EVENT_REQUEST',
    CREATE_NEW_EVENT_SUCCESS : 'CREATE_NEW_EVENT_SUCCESS',
    CREATE_NEW_EVENT_FAILURE : 'CREATE_NEW_EVENT_FAILURE',

    GET_EVENT_CATEGORIES_REQUEST : 'GET_EVENT_CATEGORIES_REQUEST',
    GET_EVENT_CATEGORIES_SUCCESS : 'GET_EVENT_CATEGORIES_SUCCESS',
    GET_EVENT_CATEGORIES_FAILURE : 'GET_EVENT_CATEGORIES_FAILURE',

    UPDATE_EVENT_CATEGORIES_REQUEST : 'UPDATE_EVENT_CATEGORIES_REQUEST',
    UPDATE_EVENT_CATEGORIES_SUCCESS : 'UPDATE_EVENTS_OVERVIEW_SUCCESS',
    UPDATE_EVENT_CATEGORIES_FAILURE : 'UPDATE_EVENT_CATEGORIES_FAILURE',

    GET_AVAILABLE_YEARS_REQUEST : 'GET_AVAILABLE_YEARS_REQUEST',
    GET_AVAILABLE_YEARS_SUCCESS : 'GET_AVAILABLE_YEARS_SUCCESS',
    GET_AVAILABLE_YEARS_FAILURE : 'GET_AVAILABLE_YEARS_FAILURE',

    SAVE_EARNING_OR_EXPENSE_REQUEST : 'SAVE_EARNING_OR_EXPENSE_REQUEST',
    SAVE_EARNING_OR_EXPENSE_SUCCESS : 'SAVE_EARNING_OR_EXPENSE_SUCCESS',
    SAVE_EARNING_OR_EXPENSE_FAILURE : 'SAVE_EARNING_OR_EXPENSE_FAILURE',

    DELETE_EARNING_OR_EXPENSE_REQUEST : 'DELETE_EARNING_OR_EXPENSE_REQUEST',
    DELETE_EARNING_OR_EXPENSE_SUCCESS : 'DELETE_EARNING_OR_EXPENSE_SUCCESS',
    DELETE_EARNING_OR_EXPENSE_FAILURE : 'DELETE_EARNING_OR_EXPENSE_FAILURE',

    SET_EVENT_PLAN_VALUE_REQUEST : 'SET_EVENT_PLAN_VALUE_REQUEST',
    SET_EVENT_PLAN_VALUE_SUCCESS : 'SET_EVENT_PLAN_VALUE_SUCCESS',
    SET_EVENT_PLAN_VALUE_FAILURE : 'SET_EVENT_PLAN_VALUE_FAILURE',

    START_EVENT_EXCEL_EXPORT_REQUEST : 'START_EVENT_EXCEL_EXPORT_REQUEST',
    START_EVENT_EXCEL_EXPORT_SUCCESS : 'START_EVENT_EXCEL_EXPORT_SUCCESS',
    START_EVENT_EXCEL_EXPORT_FAILURE : 'START_EVENT_EXCEL_EXPORT_FAILURE',

};
