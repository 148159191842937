export const budgetConstants = {
    OPEN_MODAL_BOOK_INVOICES : 'OPEN_MODAL_BOOK_INVOICES',
    OPEN_MODAL_ADJUST_FORECAST : 'OPEN_MODAL_ADJUST_FORECAST',
    OPEN_MODAL_NEW_FORECAST : 'OPEN_MODAL_NEW_FORECAST',
    OPEN_MODAL_ACCOUNTING_CONFIRMATION : 'OPEN_MODAL_ACCOUNTING_CONFIRMATION',

    TOGGLE_IST_CONFIRMATION_MODAL : 'TOGGLE_IST_CONFIRMATION_MODAL',

    START_IST_CONFIRMATION_REQUEST : 'START_IST_CONFIRMATION_REQUEST',
    START_IST_CONFIRMATION_SUCCESS : 'START_IST_CONFIRMATION_SUCCESS',
    START_IST_CONFIRMATION_FAILURE : 'START_IST_CONFIRMATION_FAILURE',

    GET_BUDGET_REQUEST : 'GET_BUDGET_REQUEST',
    GET_BUDGET_SUCCESS : 'GET_BUDGET_SUCCESS',
    GET_BUDGET_FAILURE : 'GET_BUDGET_FAILURE',

    SWITCH_TO_FORECAST : 'SWITCH_TO_FORECAST',

    GET_FORECAST_REQUEST : 'GET_FORECAST_REQUEST',
    GET_FORECAST_SUCCESS : 'GET_FORECAST_SUCCESS',
    GET_FORECAST_FAILURE : 'GET_FORECAST_FAILURE',

    ADD_FORECAST_REQUEST : 'ADD_FORECAST_REQUEST',
    ADD_FORECAST_SUCCESS : 'ADD_FORECAST_SUCCESS',
    ADD_FORECAST_FAILURE : 'ADD_FORECAST_FAILURE',

    ADJUST_BUDGET_REQUEST : 'ADJUST_BUDGET_REQUEST',
    ADJUST_BUDGET_SUCCESS : 'ADJUST_BUDGET_SUCCESS',
    ADJUST_BUDGET_FAILURE : 'ADJUST_BUDGET_FAILURE',

    SAVE_BUDGET_BWA_REQUEST : 'SAVE_BUDGET_BWA_REQUEST',
    SAVE_BUDGET_BWA_SUCCESS : 'SAVE_BUDGET_BWA_SUCCESS',
    SAVE_BUDGET_BWA_FAILURE : 'SAVE_BUDGET_BWA_FAILURE',

    OPEN_DELETE_FORECAST_MODAL : 'OPEN_DELETE_FORECAST_MODAL',
    
    DELETE_FORECAST_REQUEST : 'DELETE_FORECAST_REQUEST',
    DELETE_FORECAST_SUCCESS : 'DELETE_FORECAST_SUCCESS',
    DELETE_FORECAST_FAILURE : 'DELETE_FORECAST_FAILURE',

    SWITCH_TO_PROJECT_DETAILS : 'SWITCH_TO_PROJECT_DETAILS',
    
    GET_PROJECT_DETAILS_REQUEST : 'GET_PROJECT_DETAILS_REQUEST',
    GET_PROJECT_DETAILS_SUCCESS : 'GET_PROJECT_DETAILS_SUCCESS',
    GET_PROJECT_DETAILS_FAILURE : 'GET_PROJECT_DETAILS_FAILURE',

    UPLOAD_INVOICES_REQUEST : 'UPLOAD_INVOICES_REQUEST',
    UPLOAD_INVOICES_SUCCESS : 'UPLOAD_INVOICES_SUCCESS',
    UPLOAD_INVOICES_FAILURE : 'UPLOAD_INVOICES_FAILURE',

    REDUCE_FORECAST_REQUEST : 'REDUCE_FORECAST_REQUEST',
    REDUCE_FORECAST_SUCCESS : 'REDUCE_FORECAST_SUCCESS',
    REDUCE_FORECAST_FAILURE : 'REDUCE_FORECAST_FAILURE',

    LOAD_ALL_CREDITORS_REQUEST : 'LOAD_ALL_CREDITORS_REQUEST',
    LOAD_ALL_CREDITORS_SUCCESS : 'LOAD_ALL_CREDITORS_SUCCESS',
    LOAD_ALL_CREDITORS_FAILURE : 'LOAD_ALL_CREDITORS_FAILURE',

    OPEN_MODAL_NEW_CREDITOR : 'OPEN_MODAL_NEW_CREDITOR',

    SAVE_NEW_CREDITOR_REQUEST : 'SAVE_NEW_CREDITOR_REQUEST',
    SAVE_NEW_CREDITOR_SUCCESS : 'SAVE_NEW_CREDITOR_SUCCESS',
    SAVE_NEW_CREDITOR_FAILURE : 'SAVE_NEW_CREDITOR_FAILURE',

    UPDATE_CREDITOR_REQUEST : 'UPDATE_CREDITOR_REQUEST',
    UPDATE_CREDITOR_SUCCESS : 'UPDATE_CREDITOR_SUCCESS',
    UPDATE_CREDITOR_FAILURE : 'UPDATE_CREDITOR_FAILURE',

    DELETE_CREDITOR_REQUEST : 'DELETE_CREDITOR_REQUEST',
    DELETE_CREDITOR_SUCCESS : 'DELETE_CREDITOR_SUCCESS',
    DELETE_CREDITOR_FAILURE : 'DELETE_CREDITOR_FAILURE',

    TOGGLE_DELETE_CREDITOR_MODAL : 'TOGGLE_DELETE_CREDITOR_MODAL',
    TOGGLE_EDIT_CREDITOR_MODAL : 'TOGGLE_EDIT_CREDITOR_MODAL',

    GET_BOOKED_INVOICES_REQUEST : 'GET_BOOKED_INVOICES_REQUEST',
    GET_BOOKED_INVOICES_SUCCESS : 'GET_BOOKED_INVOICES_SUCCESS',
    GET_BOOKED_INVOICES_FAILURE : 'GET_BOOKED_INVOICES_FAILURE',

    TOGGLE_BOOKED_INVOICE_MODAL : 'TOGGLE_BOOKED_INVOICE_MODAL',

    TOGGLE_CREATE_NEXT_YEAR_MODAL: 'TOGGLE_CREATE_NEXT_YEAR_MODAL',

    TOGGLE_IST_MODAL: 'TOGGLE_IST_MODAL',

    SAVE_IST_CHANGE_REQUEST : 'SAVE_IST_CHANGE_REQUEST',
    SAVE_IST_CHANGE_SUCCESS : 'SAVE_IST_CHANGE_SUCCESS',
    SAVE_IST_CHANGE_FAILURE : 'SAVE_IST_CHANGE_FAILURE',

    CREATE_NEXT_YEAR_REQUEST: 'CREATE_NEXT_YEAR_REQUEST',
    CREATE_NEXT_YEAR_SUCCESS: 'CREATE_NEXT_YEAR_SUCCESS',
    CREATE_NEXT_YEAR_FAILURE: 'CREATE_NEXT_YEAR_FAILURE',

    START_BUDGET_EXCEL_EXPORT_REQUEST : 'START_BUDGET_EXCEL_EXPORT_REQUEST',
    START_BUDGET_EXCEL_EXPORT_SUCCESS : 'START_BUDGET_EXCEL_EXPORT_SUCCESS',
    START_BUDGET_EXCEL_EXPORT_FAILURE : 'START_BUDGET_EXCEL_EXPORT_FAILURE',

    RESET_EXCEL_LINK : 'RESET_EXCEL_LINK',

    TOGGLE_EDIT_INVOICE_MODAL : 'TOGGLE_EDIT_INVOICE_MODAL',

    UPDATE_INVOICE_REQUEST : 'UPDATE_INVOICE_REQUEST',
    UPDATE_INVOICE_SUCCESS : 'UPDATE_INVOICE_SUCCESS',
    UPDATE_INVOICE_FAILURE : 'UPDATE_INVOICE_FAILURE',

    DELETE_INVOICE_REQUEST : 'DELETE_INVOICE_REQUEST',
    DELETE_INVOICE_SUCCESS : 'DELETE_INVOICE_SUCCESS',
    DELETE_INVOICE_FAILURE : 'DELETE_INVOICE_FAILURE',

    TOGGLE_DELETION_MODAL : 'TOGGLE_DELETION_MODAL',

    UPDATE_TITLE_REQUEST: 'UPDATE_TITLE_REQUEST',
    UPDATE_TITLE_SUCCESS: 'UPDATE_TITLE_SUCCESS',
    UPDATE_TITLE_FAILURE: 'UPDATE_TITLE_FAILURE',

    UPDATE_LOWBUDGET_REQUEST: 'UPDATE_LOWBUDGET_REQUEST',
    UPDATE_LOWBUDGET_SUCCESS: 'UPDATE_LOWBUDGET_SUCCESS',
    UPDATE_LOWBUDGET_FAILURE: 'UPDATE_LOWBUDGET_FAILURE',

    SELECT_YEAR : 'SELECT_YEAR',

    GET_AVAILABLE_BUDGET_YEARS_REQUEST : 'GET_AVAILABLE_BUDGET_YEARS_REQUEST',
    GET_AVAILABLE_BUDGET_YEARS_SUCCESS : 'GET_AVAILABLE_BUDGET_YEARS_SUCCESS',
    GET_AVAILABLE_BUDGET_YEARS_FAILURE : 'GET_AVAILABLE_BUDGET_YEARS_FAILURE',

};
