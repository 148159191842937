import { authHeader } from '../_helpers';
import "isomorphic-fetch";
import { history } from '../_helpers';
import { handleResponse } from './responseHandler';

export const addressService = {
    addAddressData,
    updateAddress,
    uploadAddressFile,
    getAddresses,
    getSearchData,
};

function updateAddress(address) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(address)
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/address/update', requestOptions).then(handleResponse);
}

function  addAddressData(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/address/new', requestOptions).then(handleResponse);
}

function uploadAddressFile(data) {
    let user = JSON.parse(localStorage.getItem('hmtguser'));

    let header = {
        'Authorization': 'Bearer ' + user.access_token,
    };

    const requestOptions = {
        method: 'POST',
        headers: header,
        body: data
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/address/upload', requestOptions).then(handleResponse);
}

function getSearchData() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/address/searchdata', requestOptions).then(handleResponse);
}

function getAddresses(searchData) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(searchData)
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/address/get', requestOptions).then(handleResponse);
}
