import { userService } from './user.service';
import { history } from '../_helpers';

export function handleResponse(response) {

    let user = JSON.parse(localStorage.getItem('hmtguser'));

    if(user && user.expires_in && user.expires_in < 600){
        userService.useRefreshToken().then(
            success => {
                console.log("Refresh token use successful")
            },
            error => {
                userService.logout();
                history.push("/login", {});
            }
        );
    }

    // If unautorized, send user to Login Page
    var logoutStatuses = [401, 403];
    if (response.status && logoutStatuses.indexOf(response.status) > -1) {
                userService.logout();
                history.push("/login", {});
    }

    if (!response.ok) {
        return Promise.reject(response);
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
         return response.json();
    }else{
        return Promise.success;
    }
    
}