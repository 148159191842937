import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { navigation } from './navigation.reducer';
import { organization } from './organization.reducer';
import { users } from './users.reducer';
import { budgetdata } from './budgetdata.reducer';
import { addressdata } from './address.reducer';
import { citytours } from './citytours.reducer';
import { giftcard } from './giftcard.reducer';
import { firework } from './firework.reducer';
import { event } from './event.reducer';

const rootReducer = combineReducers({
  authentication,
  alert,
  navigation,
  organization,
  users,
  budgetdata,
  event,
  addressdata,
  citytours,
  giftcard,
  firework
});

export default rootReducer;