import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardGroup, Col, Container, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader,
    Row } from 'reactstrap';
import { userActions } from '../_actions';
import {userConstants} from "../_constants/user.constants";

class LoginPage extends React.Component {
    
    constructor(props) {
    super(props);

    // reset login status
    this.props.dispatch(userActions.logout());

    this.state = {
        email: '',
        password: '',
        submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleForgetPasswordModal = this.toggleForgetPasswordModal.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    console.log(process.env);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { email, password } = this.state;
        const { dispatch } = this.props;
        if (email && password) {
            dispatch(userActions.login(email, password));
        }
    }
    
    toggleForgetPasswordModal() {
        this.props.dispatch(userActions.toggleForgetPasswordModal());
    }

    resetPassword(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const{ email } = this.state;
        const{ dispatch } = this.props;

        if(email){
            dispatch(userActions.setUpResetPassword(email));
        }
    }

    componentDidMount() {
        this.props.dispatch({ type: userConstants.CLEAR_RESET_SUCCESS_MESSAGE});
    }

    render() {
    const { loggingIn, authentication } = this.props;
    const { email, password, submitted } = this.state;
    
    return (
        <div className="app flex-row align-items-center">
        <Modal isOpen={authentication.forgetPasswordModal} toggle={this.toggleForgetPasswordModal} className={this.props.className}>
                <ModalHeader toggle={this.toggleForgetPasswordModal}>Passwort vergessen</ModalHeader>
                <ModalBody>
                    {alert && alert.message &&
                    <div class="alert alert-danger" role="alert">
                        <div dangerouslySetInnerHTML={{ __html: alert.message }} />
                    </div>
                    }
                    <FormGroup>
                        <Label htmlFor="email">E-Mail</Label>
                        <Input type="text" placeholder="E-Mail" name="email" value={email} onChange={this.handleChange} />
                        {submitted && !email &&
                        <div className="help-block">Bitte E-Mail Adresse eingeben</div>
                        }
                    </FormGroup>
                    {authentication && authentication.errorMessages &&
                        <div class="alert alert-warning" role="alert">
                            <div dangerouslySetInnerHTML={{__html: authentication.errorMessages}} />
                        </div>
                        }
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.resetPassword}>Passwort zurücksetzen</Button>{' '}
                    <Button color="secondary" onClick={this.toggleForgetPasswordModal}>Abbrechen</Button>
                </ModalFooter>
            </Modal>
        <Container>
            <Row className="justify-content-center">
            <Col md="6" xs="12">
                <CardGroup>
                <Card>
                    <CardBody>
                    <Col xs="12" className="justify-content-center">
                        <img src="logo.png" style={{maxWidth:'60%'}} className="rounded mx-auto d-block"/><br/><br/>
                    </Col>
                    <h1>Login</h1><br/>
                        {authentication && authentication.errorMessages &&
                        <div class="alert alert-danger" role="alert">
                            <div dangerouslySetInnerHTML={{__html: authentication.errorMessages}} />
                        </div>
                        }
                    <form name="form" onSubmit={this.handleSubmit}>
                        <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="icon-user"></i>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" placeholder="E-Mail" name="email" value={email} onChange={this.handleChange} />
                        {submitted && !email &&
                            <div className="help-block">Bitte E-Mail Adresse eingeben</div>
                        }
                        </InputGroup>
                        <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="icon-lock"></i>
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder="Passwort" name="password" value={password} onChange={this.handleChange}/>
                        {submitted && !password &&
                            <div className="help-block">Bitte das Passwort eingeben</div>
                        }
                        </InputGroup>
                        {authentication && authentication.successMessage &&
                        <div class="alert alert-success" role="alert">
                            <div dangerouslySetInnerHTML={{__html: authentication.successMessage}} />
                        </div>
                        }
                    <Row>
                        <Col xs="6">
                        <Button color="primary" className="px-4 btn btn-primary">Login</Button>
                        {loggingIn &&
                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        }
                        </Col>
                        <Col xs="6" className="text-right">
                        <Button color="link" className="px-0" onClick={() => this.toggleForgetPasswordModal()}>Passwort vergessen?</Button>
                        </Col>
                    </Row>
                    </form>
                    </CardBody>
                </Card>
                </CardGroup>
            </Col>
            </Row>
        </Container>
        </div>
    );
    }
}
    
function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    const { authentication } = state;
    return {
        loggingIn,
        authentication
    };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage }; 