import { userConstants } from '../_constants';
import {organizationActions} from "../_actions";

export function users(state = {}, action) {
    switch (action.type) {
        case userConstants.REGISTER_REQUEST:
            return { registering: true, loading: true };
        case userConstants.REGISTER_SUCCESS:
            return { registering: false, success: true, loading: false, modal: false };
        case userConstants.REGISTER_FAILURE:
            return { registering: true, sucess: false, loading: false, errorMessage: action.error };

        case userConstants.TOGGLE_EDIT_USER_MODAL: {
            if (state.modal) {
                return {
                    registering: false,
                    sucess: false,
                    modal: !state.modal,
                    mode: action.mode,
                };
            }
            return {
                registering: false,
                sucess: false,
                modal: true,
                mode: action.mode,
                selectedUser: action.selectedUser
            };
        }

        case userConstants.TOGGLE_DEACTIVATE_MODAL:
            return { registering: false, sucess: false, modal: false, deactivateModal: !state.deactivateModal };
        case userConstants.DEACTIVATE_SUCCESSFUL:
            return { registering: false, sucess: false, modal: false, deactivateModal: false };
        case userConstants.ACTIVATE_SUCCESSFUL:
            return { registering: false, sucess: false, modal: false, deactivateModal: false };

        case userConstants.UPDATE_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case userConstants.UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                modal: false,
            };

        case userConstants.UPDATE_USER_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.error
            };

        case userConstants.TOGGLE_USER_RETIREMENT_MODAL:
            return {
                ...state,
                retirementModal: !state.retirementModal,
                retirementUser: action.user
            };

        case userConstants.TOGGLE_USER_RETIREMENT_REQUEST:
            return {
                ...state,
                retireLoading: true,
            };

        case userConstants.TOGGLE_USER_RETIREMENT_SUCCESS:
            return {
                ...state,
                retireLoading: false,
                retirementModal: false
            };

        case userConstants.TOGGLE_USER_RETIREMENT_FAILURE:
            return {
                ...state,
                retireLoading: false,
                errorMessage: action.error
            };

        default:
            return state;
    }
}