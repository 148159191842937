import { authHeader } from '../_helpers';
import "isomorphic-fetch";
import { history } from '../_helpers';
import { handleResponse } from './responseHandler';

export const citytoursService = {
    getCityTour,
    getCityTours,
    updateCityTour,
    deleteCityTour,

    getMeetingPoints,
    updateMeetingPoint,
    deleteMeetingPoint,

    getAvailableCityTours,
    updateAvailableCityTour,
    deleteAvailableCityTour,

    getBookedVisitors,
    updateBookedVisitor,
    deleteBookedVisitor,

    getTourGuides,
    updateTourGuide,
    deleteTourGuide,
    prepareExcelExport,
    uploadCityToursData
};

function uploadCityToursData(data) {
    let user = JSON.parse(localStorage.getItem('hmtguser'));

    let header = {
        'Authorization': 'Bearer ' + user.access_token,
    };

    const requestOptions = {
        method: 'POST',
        headers: header,
        body: data
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL+'/citytours/import', requestOptions).then(handleResponse);
}

function prepareExcelExport(externalUUID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/citytours/export/' + externalUUID, requestOptions).then(handleResponse);
}

function getCityTour(externalUUID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/citytours/tour/' + externalUUID, requestOptions).then(handleResponse);
}

function getCityTours(startDate, endDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let url = '/citytours/tours';

    if (startDate) {
        url = url + '?start=' + startDate;
    }

    if (endDate) {
        if (startDate) {
            url += "&";
        } else {
            url += "?";
        }
        url = url + 'end=' + endDate;
    }

    return window.fetch(process.env.REACT_APP_API_BASE_URL + url, requestOptions).then(handleResponse);
}

function updateCityTour(tour) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(tour)
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/citytours/tour', requestOptions).then(handleResponse);
}

function deleteCityTour(externalUUID) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/citytours/tour/' + externalUUID, requestOptions).then(handleResponse);
}

function getAvailableCityTours() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/citytours/tour/available', requestOptions).then(handleResponse);
}

function updateAvailableCityTour(list) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(list)
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/citytours/tour/available', requestOptions).then(handleResponse);
}

function deleteAvailableCityTour(externalUUID) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/citytours/tour/available/' + externalUUID, requestOptions).then(handleResponse);
}

function getBookedVisitors(externalUUID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/citytours/visitor?tour=' + externalUUID, requestOptions).then(handleResponse);
}

function updateBookedVisitor(visitor) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(visitor)
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/citytours/visitor', requestOptions).then(handleResponse);
}

function deleteBookedVisitor(externalUUID) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/citytours/visitor/' + externalUUID, requestOptions).then(handleResponse);
}

function getTourGuides() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/citytours/guide', requestOptions).then(handleResponse);
}

function updateTourGuide(guide) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(guide)
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/citytours/guide', requestOptions).then(handleResponse);
}

function deleteTourGuide(externalUUID) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/citytours/guide/' + externalUUID, requestOptions).then(handleResponse);
}

function getMeetingPoints() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/citytours/meetingpoint', requestOptions).then(handleResponse);
}

function updateMeetingPoint(guide) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(guide)
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/citytours/meetingpoint', requestOptions).then(handleResponse);
}

function deleteMeetingPoint(externalUUID) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };

    return window.fetch(process.env.REACT_APP_API_BASE_URL + '/citytours/meetingpoint/' + externalUUID, requestOptions).then(handleResponse);
}
